import React from 'react'
import MLogo from '../../../../assets/icons/mLogo.png'
import SLogo from '../../../../assets/icons/sLogo.svg'
import OLogo from '../../../../assets/icons/oLogo.png'

export const AdminCourseCard = ({ info }) => {
    return (
        <div data-testid={`${info.id}-course-card`} id="top-course-card">
            <div className="img-cont">
                <img
                    src={
                        info.type !== 1
                            ? info.type === 2
                                ? SLogo
                                : OLogo
                            : MLogo
                    }
                    alt=""
                />
            </div>
            <div className="info-cont">
                <div className="top-layer">
                    <p data-testid="course-name">{info.name}</p>
                    <p>
                        <span className="priceSymbol" data-testid="priceSymbol">
                            &#36;
                        </span>
                        <span>{info.revenue}</span>
                    </p>
                </div>
                <div className="lower-layer">
                    <p data-testid="value">
                        {info.type !== 1 && <span>&#36;</span>}
                        <span>{info.value}</span>
                        {info.type === 1 && <span> units</span>}
                    </p>
                    <p data-testid="dynamic-cont">
                        <span>{info.count}</span>
                        <span className="dynamic">
                            {info.type !== 1
                                ? info.type === 2
                                    ? ' subscribed'
                                    : ' purchased'
                                : ' completed'}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}