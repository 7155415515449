import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom'
import { useInstructorData } from '../../../../hooks';
import { Coursecard } from '../../MyCourses/courseCard/index'
import SearchIcon from '../../../../assets/icons/search.svg'
import { RequestLoader } from '../../../../components/Loading'
import  { Filter } from '../../../../components/InstructorCourseFilter/courseFilter'

export function CourseSecCont() {
    const {
        state: { isLoading, courses, isFiltered, filteredCourse, instructorID }
    } = useInstructorData()

    const [inputValue, setInputvalue] = useState('')
    const [searchParam, setSearchParam] = useState('')
    const [error, showError] = useState(false)
    const [list, setList] = useState([])

    const handleSearch = (param) => {
        if (param === '') {
            showError(false)
            setList(courses)
        } else {
            const searchResult = courses.filter((course) => course.name.toLowerCase().includes(param.toLowerCase()))
            if (searchResult.length < 1) {
                showError(true)
                setList(searchResult)
            } else {
                showError(false)
                setList(searchResult)
            }
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchParam(inputValue)
        }, 1500)

        return () => clearTimeout(timerId)
    }, [inputValue])

    useEffect(() => {
        handleSearch(searchParam)
        // eslint-disable-next-line
    }, [searchParam])

    useEffect(() => {
        if (instructorID) {
            setList(courses)
        }
        return () => {
            setList([])
        }
    }, [instructorID, courses])

    return (
        <section>
            <div className="search__et__create">
                <div className="input__cont">
                    <Input
                        placeholder="Input search text"
                        value={inputValue}
                        onChange={(e) => setInputvalue(e.target.value)}
                    />
                    <div
                        onClick={() => handleSearch()}
                        className="search__cont"
                    >
                        <img src={SearchIcon} alt="search Icon" />
                    </div>
                </div>
                <Link to="/tutor/create-course">
                    <Button className="create_btn" type="primary">
                        {' '}
                        Create Course
                    </Button>
                </Link>
            </div>
            <div className="filter__section">
                <Filter />
            </div>
            <div className="card__wrapper">
                {isLoading && (
                    <div className="loader_cont">
                        <RequestLoader />
                    </div>
                )}
                {!isLoading && courses.length < 1 && !error &&  (
                    <div className="empty_data">
                        <h4>
                            You have not created your first course yet.
                        </h4>
                        <h4>Start creating your first course now</h4>
                    </div>
                )}
                {/* Error message for empty filter result */}
                {(isFiltered && filteredCourse.length < 1) &&
                    <div className="empty_data">
                        <h4>
                            No course for selected filter
                        </h4>
                    </div>}
                {/* Rendering fectched course after loading and when filter is not in use */}
                {(!isLoading && !isFiltered) &&
                    list.length >= 1 &&
                    list.map((listCont, idx) => {
                        return <Coursecard key={idx} type={listCont} />
                    })}
                {/* Rendering filtered course */}
                {isFiltered &&
                    filteredCourse.map((listCont, idx) => {
                        return <Coursecard key={idx} type={listCont} />
                    })}
                {error &&
                    <div className="empty_data">
                        <h4>Course with name {inputValue} does not exist</h4>
                        <h4>Click on create lessons to start now</h4>
                    </div> }
            </div>
        </section>
    )
}