import axios from 'axios';
import { getAuthToken, clearStorage } from './helpers';
// import { StripeSupported } from '../pages/landing/pricing/Payment/currencySymbols';

// Create a function to fetch the country code from ipapi and store it in localStorage
// const fetchCountryCode = async () => {
//     const allowedCountries = []
//     for (let i = 0; i < StripeSupported.length; i++) {
//         allowedCountries.push(StripeSupported[i].countryCode)
//     }
//     try {
//         // const request = await fetch('https://ipapi.co/json');
//         // const request = await fetch('https://ipinfo.io/json?token=011f0703627471')
//         // const data = await request.json();
//         // console.log(data)
//         const data = '';
//         localStorage.setItem('zustech:user-location', JSON.stringify(data))
//         if (allowedCountries.includes(data.country_code)) {
//             localStorage.setItem('currentCountry', JSON.stringify('GB'))
//             return 'GB'
//         } else {
//             localStorage.setItem('currentCountry', JSON.stringify('NG'))
//             return 'NG'
//         }
//     } catch (error) {
//         // const request = await fetch('https://ipinfo.io/json?token=011f0703627471')
//         // const jsonResponse = await request.json()
//         // console.log(jsonResponse)
//         console.error('Error fetching country code:', error);
//         throw error;
//     }
// };

const options = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: 'application/json,text/plain,*/*',
        'Content-Type': 'application/json',
    },
};

export const axiosInstance = axios.create(options);
// Initialize options for axios instance
const initAxiosInstance = async () => {
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // Set up interceptors for request and response
    axiosInstance.interceptors.request.use(
        async (config) => {
            await delay(2000); // Wait for 2 seconds
            const token = getAuthToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            // Get country code, either from localStorage
            const countryCode = localStorage.getItem('currentCountry');
            if (!countryCode) {
                // If no country code is stored, don't send request
                return Promise.reject(new Error('User location is not ready. Request aborted.'))
            }
            config.headers.Country = countryCode;
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            try {
                const errorData = error?.response;
                if (errorData?.status === 401) {
                    clearStorage();
                    window.location.reload();
                }
                return Promise.reject(error);
            } catch (err) {
                return Promise.reject(err);
            }
        }
    );

    return axiosInstance;
};

// Export the axios instance, ensuring it's created asynchronously with country code
export const request = (async () => await initAxiosInstance())();