import React, { useState, useEffect } from 'react'
import { Twirl as Hamburger } from 'hamburger-react'
import { NavLink, useHistory } from 'react-router-dom'
import { Avatar } from 'antd'
import './Header.scss'
import DisplayPhoto from '../../../../assets/icons/Ellipse.svg'
import ArrowRight from '../../../../assets/icons/arrow_right.svg'
// import Notify from '../../../../assets/icons/fi_bell.svg'
// import Message from '../../../../assets/icons/messageIcon.svg'
import { CartIcon } from '../../../PremiumCourse/CartIcon'
import { useAuth, useInstructorData } from '../../../../hooks'
import { findInstructorByUserId } from '../../../../services'

export const Header = (props) => {
    const { isOpen, setOpen, setShowSideBar } = props

    const [instructorApplication, setinstructorApplication] = useState({
        link: '',
        name: '',
    })
    const [isInstructor, setIsInstructor] = useState(null)
    const [applicationStatus, setApplictionStatus] = useState(null)

    const history = useHistory()

    const {
        state: { data: user, isLoggedIn },
    } = useAuth()

    const {
        getInstructorID
    } = useInstructorData()

    const regEx = /^\/me(\/[a-z]+-?[a-z]+?)?(\/[a-z]+?-?[a-z]?(\d+)?)?(\/(\d+)?([a-z]+)?-?([a-z]+)?)?$/ig
    // know the current page been rendered base on the url
    const loc = document.location.pathname

    const profilePic = () => {
        if (user?.profilePic) {
            return <Avatar size="large" id="profilePic" src={user.profilePic} />
        } else {
            return <img src={DisplayPhoto} alt="Profile pics" />
        }
    }

    useEffect(() => {
        const sendRequest = async () => {
            try {
                getInstructorID(user?.id)
                const res = await findInstructorByUserId(user?.id)
                if (res.status && res.data.instructorApplicationStatus === 1) {
                    setIsInstructor(true)
                    setApplictionStatus(res.data.instructorApplicationStatus)
                } else {
                    setIsInstructor(false)
                    setApplictionStatus(res.data.instructorApplicationStatus)
                }
            } catch (error) {
                console.error(error.message)
                setIsInstructor(false)
                setApplictionStatus(2)
            }
        }
        if (isLoggedIn) {
            sendRequest()
        }
        return () => {
            setIsInstructor(null)
            setApplictionStatus(null)
        }

        // eslint-disable-next-line
    }, [user?.id])

    // CHECK IF USER IS AN INSTRUCTOR OR NOT
    useEffect(() => {
        const getStatus = () => {
            if (isInstructor && applicationStatus === 1 && regEx.test(loc)) {
                // If user is an Instructor, application has been approved and is in user's dashboard
                setinstructorApplication({
                    'link': '/tutor',
                    'name': 'Switch to Instructor Dashboard',
                    'id': 'switch-to-instructor',
                })
            } else if (isInstructor && applicationStatus === 1 && !regEx.test(loc)) {
                // If user is an Instructor, application has been approved and is not in user's dashboard
                setinstructorApplication({
                    link: '/me',
                    name: 'Switch to Student Dashboard',
                    id: 'switch-to-student',
                })
            } else if (isInstructor === false && applicationStatus > 1 && regEx.test(loc)) {
                // if user is not an instructor and is not in user's dashboard
                setinstructorApplication({
                    link: '/me/instructor-onboarding-application',
                    name: 'Become an instructor',
                    id: 'become-an-instructor',
                })
            } else if (isInstructor === false && applicationStatus !== 1 && !regEx.test(loc)) {
                // if user is an instructor and is not in user's dashboard
                setinstructorApplication({
                    link: '/me/instructor-onboarding-application',
                    name: 'Become an instructor',
                    id: 'become-an-instructor',
                })
                history.push('/me')
            }
        }
        getStatus()
        // eslint-disable-next-line
    }, [isInstructor, applicationStatus])

    return (
        <div id="learnerHeader">
            <div className="first_Section">
                <Hamburger
                    // className="hamburger-react"
                    color="#3a86ff"
                    direction="left"
                    label="Show menu"
                    toggled={isOpen}
                    toggle={setOpen}
                    onToggle={(toggled) => {
                        if (toggled) {
                            // open a menu
                            setShowSideBar(true)
                        } else {
                            // close a menu
                            setShowSideBar(false)
                        }
                    }}
                />
                <div className="detail">
                    <div className="Profile__cont">{profilePic()}</div>
                    <div className="Header__detail">
                        <p>Hello</p>
                        <p>
                            {user?.firstName} {user?.lastName} !
                        </p>
                    </div>
                </div>
            </div>

            <div className="Header__CTA">
                <div className="icon__cont">
                    {/* <img src={Notify} alt="notification" />
                    <img src={Message} alt="message" /> */}
                    <CartIcon />
                </div>
                <div className="main__CTA">
                    <NavLink
                        // knowing what path to render base on current page
                        to={instructorApplication.link}
                        id={instructorApplication.id}
                    >
                        {' '}
                        {instructorApplication.name}
                        <span className="arrow">
                            <img src={ArrowRight} alt="arrow_right" />
                        </span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
