import React, { useState } from 'react'
import {
    // PaymentElement,
    CardElement,
    // LinkAuthenticationElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'
import './checkOutElement.scss'
import { openNotification } from '../../../../../utils/helpers'
import { createIntentCardPayment } from '../../../../../services'

export default function CheckoutForm({ paymentPurpose, data, setPaymentSucess }) {
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [disabled, setDisabled] = useState(data.isPlanActive)
    const [succeeded, setSuceeded] = useState(false)
    const [loadError, setLoadError] = useState(false)
    // const [checkOutInProgress, setCheckOutInprogress] = useState(false)
    const [returnUrl, setReturnUrl] = useState(
        `${process.env.REACT_APP_BASE_URL}/me/subscription/success`
    )
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.log('Stripe / Element has not loaded yet')
            return
        }
        setIsLoading(true)
        const { methodError, paymentMethod } = await stripe.createPaymentMethod(
            {
                type: 'card',
                card: elements.getElement(CardElement),
            }
        )

        if (!methodError) {
            // get paymetMethodId and set to sent payment intent
            try {
                const checkoutData = {
                    productId: parseInt(data.planId, 10),
                    currency: data.currency,
                    email: data?.user.email,
                    subscription: data.subscriptionType,
                    courseId:
                        data.subscriptionType === 2
                            ? parseInt(data.courseId, 10)
                            : 0,
                    paymentMethodId: paymentMethod.id,
                }
                // send a request to the endpoint and retrive the client_secret key
                const res = await createIntentCardPayment(checkoutData)
                if (res.data && res.data.clientSecret !== '') {
                    const subSuccessDta = {
                        Subscribed_Plan: data.planId,
                        Subscription_Type: data.subscriptionType,
                        Course_Id:
                            data.subscriptionType === 2 ? data.courseId : 0,
                    }
                    try {
                        if (data.isFreePlan) {
                            const { error, setupIntent } =
                                await stripe.confirmCardSetup(
                                    res.data.clientSecret,
                                    {
                                        payment_method: paymentMethod.id,
                                        return_url: returnUrl,
                                    }
                                )
                            if (error) {
                                setLoadError(true)
                                setMessage(error.message)
                                // history.push('/me/subscription')
                            } else {
                                if (setupIntent.status === 'succeeded') {
                                    subSuccessDta.setupResult = setupIntent
                                    sessionStorage.setItem(
                                        'Subscribed_Data',
                                        JSON.stringify(subSuccessDta)
                                    )
                                    setMessage('Payment succeeded!')
                                    setSuceeded(true)
                                    history.push('/me/subscription/success')
                                } else {
                                    setMessage(
                                        'An unexpected payment error occurred.'
                                    )
                                }
                                setReturnUrl(
                                    `${process.env.REACT_APP_BASE_URL}/me/subscription/success`
                                )
                            }
                        } else {
                            const { paymentError, paymentIntent } =
                                await stripe.confirmCardPayment(
                                    res.data.clientSecret,
                                    {
                                        payment_method: paymentMethod.id,
                                        return_url: returnUrl,
                                    }
                                )
                            if (paymentError) {
                                setLoadError(true)
                                setMessage(paymentError.message)
                                // history.push('/me/subscription')
                            } else {
                                if (paymentIntent?.status === 'succeeded') {
                                    subSuccessDta.paymentResult = paymentIntent
                                    sessionStorage.setItem(
                                        'Subscribed_Data',
                                        JSON.stringify(subSuccessDta)
                                    )
                                    setMessage('Payment succeeded!')
                                    setSuceeded(true)
                                    if (paymentPurpose === 'COP') {
                                        setPaymentSucess(true) // should be true if the payment is for change of plan, it opens a modal
                                    } else {
                                        history.push('/me/subscription/success')
                                    }
                                } else {
                                    setMessage(
                                        'An unexpected payment error occurred.'
                                    )
                                }
                                setReturnUrl(
                                    `${process.env.REACT_APP_BASE_URL}/me/subscription/success`
                                )
                            }
                        }
                        setIsLoading(false)
                    } catch (err) {
                        console.error(err)
                    }
                    // Run request for stripe
                    // if (country !== ('NG' || 'ZA' || 'GH' || 'KE')) {
                    //     setClientSecret(res.data.clientSecret)
                    //     const subSuccessDta = {
                    //         'Subscribed_Plan': planId,
                    //         'Paystack_PaymentRef': res.data.clientSecret,
                    //         'Subscription_Type': subscriptionType,
                    //         'Course_Id': subscriptionType === 2 ? courseId : 0
                    //     }
                    //     localStorage.setItem('Subscribed_Data', JSON.stringify(subSuccessDta))
                    //     // localStorage.setItem('paymentRef', JSON.stringify(res.data.clientSecret))
                    // } else {
                    //     setPaystackRef(res.data.clientSecret)
                    //     // store planId in localStorage
                    //     localStorage.setItem('Paystack paymentRef', JSON.stringify(res.data.clientSecret))
                    //     // subSuccessDta['Paystack_PaymentRef'] = res.data.clientSecret
                    // }
                    // localStorage.setItem('Subscribed_Data', JSON.stringify(subSuccessDta))
                    // localStorage.removeItem('SubCourseId')
                } else {
                    history.push('/me/subscription')
                }
                setIsLoading(false)
            } catch (err) {
                openNotification({
                    title: 'Payment failed',
                    message: err.message,
                    type: 'error',
                })
                setLoadError(true)
                setMessage(err.message)
                setIsLoading(false)
            }
        }
        // const { paymentError, paymentResponse } =
        //     await stripe.confirmCardPayment(
        //         clientSecret,
        //         {
        //             payment_method: paymentMethod.id,
        //             return_url: returnUrl
        //         })
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
    }

    const handleLoadError = async (event) => {
        // Listen for load errors, handling any errors using your own observability tooling.
        console.log(event)
        setLoadError(true)
        setMessage("Couldn't load card element")
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty)
        setLoadError(true)
        setMessage(event.error ? event.error.message : '')
    }
    // const cardStyle = {
    //     style: {
    //         base: {
    //             color: '#32325d',
    //             fontFamily: 'Arial, sans-serif',
    //             fontSmoothing: 'antialiased',
    //             fontSize: '16px',
    //             '::placeholder': {
    //                 color: '#32325d',
    //             },
    //         },
    //         invalid: {
    //             fontFamily: 'Arial, sans-serif',
    //             color: '#fa755a',
    //             iconColor: '#fa755a',
    //         },
    //     },
    // }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement
                id="card-element"
                // options={cardStyle}
                onChange={handleChange}
                onLoadError={handleLoadError}
            />
            {/* Show any error that happens when processing the payment */}
            {loadError && (
                <div className="card-error" role="alert">
                    {message}
                </div>
            )}
            <button
                disabled={isLoading || disabled || succeeded}
                id="payment-submit"
            >
                <span id="button-text">
                    {isLoading ? (
                        <div className="spinner" id="spinner">
                            ...
                        </div>
                    ) : (
                        'Pay now'
                    )}
                </span>
            </button>
        </form>
    )
}