/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Table } from 'antd'
import {
    getAllPlans,
    getSubscriptionHistory,
    getBenefitsByPlanId,
} from '../../../../services'
import { ActiveplanCard } from './activeSubCard/activeSubCard'
import { openNotification } from '../../../../utils/helpers'
import { useAuth, useLocation } from '../../../../hooks'
import { RequestLoader } from '../../../../components/Loading'
import './pricing.scss'
import Ongreen from '../../../../assets/icons/Ongreen.svg'
import Ongray from '../../../../assets/icons/Ongray.svg'
import Onblue from '../../../../assets/icons/Onblue.svg'
// import { StripeApp } from '../../../userDashboard/Settings/Subscription/Stripe/stripe'

const Pricing = () => {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [dataSource, setDataSource] = useState(null)
    const loc = document.location.pathname
    // const loc = '/me/subscription'
    const [openModal, setOpenModal] = useState(false)
    const [fetchingPlans, setFetchingPlan] = useState(false)
    const [activeSub, setActiveSub] = useState(null)
    const {
        state: { activeSub: userSub },
    } = useAuth()
    const {
        state: { currencySymbol }
    } = useLocation()

    useEffect(() => {
        const getSubscriptions = async () => {
            try {
                // const plans = []
                const response = await getAllPlans()
                for (let i = 0; i < response.length; i++) {
                    if (response[i].planType === 1) {
                        const planBenefit = await getPlanBenefits(response[i].id)
                        setSubscriptionPlans((prev) =>
                            [
                                ...prev,
                                { ...response[i], benefits: planBenefit.benefits },
                            ].sort((a, b) => a.id - b.id)
                        )
                    }
                }
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
                setFetchingPlan(false)
            }
        }

        const getPlanBenefits = async (id) => {
            try {
                const planBenefit = await getBenefitsByPlanId(id)
                return planBenefit
            } catch (error) {
                console.error(error.message)
            }
        }

        setFetchingPlan(true)
        getSubscriptions()
        setFetchingPlan(false)

        if (loc !== '/pricing') {
            setActiveSub(userSub)
        }

        // eslint-disable-next-line
    }, [userSub])

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    const planData = {
        Annual: {
            name: 'Annual Plan',
            sellPoint: 'Best Value',
            benefits: [
                'Save 30%',
                'Gain unlimited access to courses taught by skilled professionals.',
                'View and Download professional certificates to boost your profile.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.',
            ],
        },
        Monthly: {
            name: 'Monthly Plan',
            sellPoint: 'Most Popular',
            benefits: [
                'Gain unlimited access to courses taught by skilled professionals.',
                'View and Download professional certificates to boost your profile.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.',
                'Reach out for customer support via email and phone.',
            ],
        },
        Free: {
            name: '7 Day Free Trial',
            sellPoint: '',
            benefits: [
                'Gain unlimited access to courses taught by skilled professionals.',
                'View professional certificates.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.',
                'Reach out for customer support via email and phone.',
            ],
        },
    }

    function getPlanDetail(id) {
        let amount
        let name
        if (subscriptionPlans) {
            subscriptionPlans.forEach((plan) => {
                if (plan.id === id) {
                    amount = plan.amount
                    name = plan.name
                }
            })
        }

        return { amount, name }
    }

    useEffect(() => {
        const getSubHistory = async () => {
            try {
                const arr = []
                const res = await getSubscriptionHistory(1, 300)
                // subscription timestamp is needed (gotten from sub history), amount is gotten from plan details
                if (res.data.length >= 1) {
                    const data = res.data
                    data.forEach((obj, idx) => {
                        const { amount, name } = getPlanDetail(obj.planId)
                        const subInfo = {}
                        switch (obj.subscriptionStatus) {
                            // Check style variable file for the color codes
                            // Active - secondaryColor, Inactive - grey2, Sucessfully - successColor, cancelled - tertiaryColor, expired - errorColor
                            case 1:
                                subInfo['status'] = 'Active'
                                subInfo['color'] = '#3a86ff'
                                break
                            case 2:
                                subInfo['status'] = 'Inactive'
                                subInfo['color'] = '#4f4f4f'
                                break
                            case 3:
                                subInfo['status'] = 'Successfully'
                                subInfo['color'] = '#46d5b3'
                                break
                            case 4:
                                subInfo['status'] = 'Cancelled'
                                subInfo['color'] = '#ffcc00'
                                break
                            case 5:
                                subInfo['status'] = 'Expired'
                                subInfo['color'] = '#eb5757'
                                break
                            default:
                                subInfo['status'] = 'Inactive'
                                subInfo['color'] = '#4f4f4f'
                                break
                        }
                        arr.push({
                            key: idx,
                            startDate: getReadableDate(
                                obj.subscription_start_timestamp
                            ),
                            plan: name,
                            amount: `${currencySymbol} ${amount}`,
                            endDate: getReadableDate(
                                obj.subscription_end_timestamp
                            ),
                            status: (
                                <h6
                                    style={{
                                        fontSize: '1em',
                                        color: `${subInfo.color}`,
                                    }}
                                    data-testid="transaction-status"
                                >
                                    {subInfo.status}
                                </h6>
                            ),
                        })
                    })
                    setDataSource(arr)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        if (loc === '/me/subscription') {
            getSubHistory()
        }
        // eslint-disable-next-line
    }, [subscriptionPlans])

    const columns = [
        {
            title: 'Plan:',
            dataIndex: 'plan',
            key: 'plan',
            width: 160,
        },
        {
            title: 'Amount:',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Start Date:',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 180,
        },
        {
            title: 'End Date:',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 180,
        },
        {
            title: 'Status:',
            dataIndex: 'status',
            key: 'status',
            width: 100,
        },
    ]

    return (
        <section id="general_pricingg">
            {/* <DataDisplay /> */}
            <div className="wave-bg">
                {loc === '/pricing' ? (
                    <div className="header">
                        <h2>Pricing</h2>
                        <p>
                            We provide competitive and pocket friendly pricing
                            for our wide range of courses.
                        </p>
                    </div>
                ) : (
                    <div className="history_link">
                        <div className="active_sub">
                            {/*  && Object.keys(activeSubInfo).length >= 1 */}
                            <ActiveplanCard subPlans={subscriptionPlans} />
                        </div>
                        <div
                            id="paymtHisBtn"
                            data-testid="paymtHisBtn"
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        >
                            Payment History
                        </div>
                    </div>
                )}
                <div className="pricing-container">
                    {fetchingPlans && <RequestLoader />}
                    {!fetchingPlans && (
                        <div className="content container">
                            {/* Start of pricing cards */}
                            <div className="pricing-cards">
                                {/* FIRST PRICE CARD */}
                                {/* this card (first card) should not show when user has no active subs */}
                                {!activeSub && (
                                    <div
                                        data-testid="priceCard"
                                        className="first-card cards"
                                    >
                                        {subscriptionPlans
                                            .slice(0, 1)
                                            .map((plan) => (
                                                <div key={plan.id}>
                                                    <div className="top left">
                                                        <p className="frequency-long">
                                                            7 Days {plan.name}
                                                        </p>
                                                        <h4 id="currency-price">
                                                            {currencySymbol}
                                                            {plan.amount}
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        <ul className="first-feature feature">
                                                            {plan.benefits.map(
                                                                (
                                                                    goods,
                                                                    idx
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            idx
                                                                        }
                                                                        className="first-feature-item"
                                                                    >
                                                                        <div>
                                                                            <img
                                                                                src={
                                                                                    Ongray
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <p>
                                                                            {
                                                                                goods
                                                                            }
                                                                        </p>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <Link
                                                        id={`paymentBtn-${plan?.id}`}
                                                        to={`payment/${plan?.id}`}
                                                        className="btn-subscription btn-first"
                                                        onClick={() => {
                                                            // Storing the id of course to subscribe for and the duration for calculating total amount
                                                            const paymentInfo =
                                                                {
                                                                    subscriptionType: 1,
                                                                }
                                                            localStorage.setItem(
                                                                'SubCourseId',
                                                                JSON.stringify(
                                                                    paymentInfo
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        Get Started
                                                    </Link>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                {/* MIDDLE PRICE CARD */}
                                <div
                                    data-testid="priceCard"
                                    className={
                                        activeSub
                                            ? 'second-card center cards simple'
                                            : 'second-card center cards'
                                    }
                                >
                                    {subscriptionPlans
                                        .slice(1, 2)
                                        .map((plan) => (
                                            <div
                                                key={plan.id}
                                                className={
                                                    activeSub?.planId ===
                                                    plan?.id
                                                        ? 'head active'
                                                        : 'head'
                                                }
                                            >
                                                <div className="top top-center">
                                                    <p id="statement">
                                                        {
                                                            planData?.plan
                                                                ?.name?.[
                                                                    'sellPoint'
                                                                ]
                                                        }
                                                    </p>
                                                    <p className="frequency-long">
                                                        {plan.name} Plan
                                                    </p>
                                                    <h4 id="currency-price">
                                                        {currencySymbol}
                                                        {plan.amount}{' '}
                                                        <span id="span">
                                                            /mo
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div>
                                                    <ul className="second-feature feature">
                                                        {plan.benefits.map(
                                                            (goods, idx) => (
                                                                <li
                                                                    key={idx}
                                                                    className="second-feature-item"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                Ongreen
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {goods}
                                                                    </p>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <Link
                                                    id={`paymentBtn-${plan?.id}`}
                                                    to={`payment/${plan?.id}`}
                                                    className="btn-subscription btn-second"
                                                    onClick={() => {
                                                        // Storing the id of course to subscribe for and the duration for calculating total amount
                                                        const paymentInfo = {
                                                            subscriptionType: 1,
                                                        }
                                                        sessionStorage.setItem(
                                                            'SubCourseId',
                                                            JSON.stringify(
                                                                paymentInfo
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Get Started
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                {/* LAST PRICE CARD */}
                                <div
                                    data-testid="priceCard"
                                    className={
                                        activeSub
                                            ? 'last-card cards simple'
                                            : 'last-card cards'
                                    }
                                >
                                    {subscriptionPlans
                                        .slice(2, 3)
                                        .map((plan) => (
                                            <div
                                                key={plan.id}
                                                className={
                                                    activeSub?.planId ===
                                                    plan.id
                                                        ? 'head active'
                                                        : 'head'
                                                }
                                            >
                                                <div className="top right">
                                                    <p id="statement">
                                                        {
                                                            planData?.[
                                                                plan?.name
                                                            ]['sellPoint']
                                                        }
                                                    </p>
                                                    <p className="frequency-long">
                                                        {plan.name} Plan
                                                    </p>
                                                    <h4 id="currency-price">
                                                        {currencySymbol}
                                                        {plan.amount}{' '}
                                                        <span id="span">
                                                            /yr
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div>
                                                    <ul className="last-feature feature">
                                                        {plan.benefits.map(
                                                            (goods, idx) => (
                                                                <li
                                                                    key={idx}
                                                                    className="third-feature-item"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                Onblue
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {goods}
                                                                    </p>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                                <Link
                                                    id={`paymentBtn-${plan?.id}`}
                                                    to={`payment/${plan?.id}`}
                                                    className="btn-subscription btn-last"
                                                    onClick={() => {
                                                        // Storing the id of course to subscribe for and the duration for calculating total amount
                                                        const paymentInfo = {
                                                            subscriptionType: 1,
                                                        }
                                                        localStorage.setItem(
                                                            'SubCourseId',
                                                            JSON.stringify(
                                                                paymentInfo
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Get Started
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* PAYMENT HISTORY MODAL */}
            <Modal
                open={openModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setOpenModal(false)
                }}
                width={800}
                id="paymtHistoryModal"
                data-testid="paymtHistoryModal"
            >
                <h2 data-testid="hisTitle" className="title">
                    Payment History
                </h2>
                <div className="histoy_detail">
                    {dataSource ? (
                        <Table
                            data-testid="historyTable"
                            id="historyTable"
                            dataSource={dataSource}
                            columns={columns}
                            scroll={{
                                y: 500,
                            }}
                        />
                    ) : (
                        <h4 data-testid="noData">No Payment History Yet</h4>
                    )}
                </div>
            </Modal>
        </section>
    )
}

export default Pricing
