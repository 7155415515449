import React from 'react'
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

export const AccordionComponent = ({ question, answers }) => {
    return (
        <div>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>{question}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {typeof answers === 'string' && <p>{answers}</p>}
                    {typeof answers !== 'string' &&
                        answers.map((ans) => <p>{ans}</p>)}
                </AccordionItemPanel>
            </AccordionItem>
        </div>
    )
}