import React from 'react'
import { Provider } from 'react-redux'
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import reducers from './reducers'

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // https://github.com/zalmoxisus/redux-devtools-extension

const initialState = {}
// Create Redux store with middleware and dev tools integration
export const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
)
export const Root = ({ children }) => {
    return <Provider store={store}>{children}</Provider>
}
