import React from 'react'
import ReactPlayer from 'react-player/lazy'

export const RecordingLessonsAndMediaQualityStandard = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>
                Recording Lessons and Media Quality Standard
            </h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>

            <div>
                <p style={{ padding: '25px 0px' }}>
                    Creating engaging video content is a great way to enhance
                    the learning experience of our learners. Here’s a
                    step-by-step guide to help you get started.
                </p>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Step 1: Plan Your Content</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Define Your Objectives:</strong> What do you
                            want your students to learn from the video or audio
                            recording?
                        </li>
                        <li>
                            <strong>Outline Your Script:</strong> Write a script
                            or bullet points to ensure you cover all important
                            topics.
                        </li>
                        <li>
                            <strong>Choose the Right Format:</strong> Decide if
                            you want to create a lesson, demonstration,
                            interview, or discussion format.
                        </li>
                        <li>
                            <strong>Reading:</strong> Graphic materials, book
                            excerpts, or online resources.
                        </li>
                        <li>
                            <strong>Multimedia:</strong> Use images,
                            infographics, and videos to enhance learning.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Step 2: Set Up Your Equipment</h3>
                    <h4>For Video:</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Camera:</strong> Use a good-quality webcam
                            or a DSLR camera.
                        </li>
                        <li>
                            <strong>Microphone:</strong> Use a good external
                            microphone for clear audio.
                        </li>
                        <li>
                            <strong>Lighting:</strong> Use natural light or
                            softbox lights to ensure you’re well-lit.
                        </li>
                        <li>
                            <strong>Background:</strong> Choose a clean,
                            uncluttered background or a virtual background.
                        </li>
                    </ul>

                    <h4>For Audio:</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Microphone:</strong> A good USB microphone
                            or a lapel mic can significantly improve audio
                            quality.
                        </li>
                        <li>
                            <strong>Recording Environment:</strong> Choose a
                            quiet space and eliminate background noise.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Step 3: Record Your Content</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Test Your Recording Area:</strong> Ensure
                            your camera is at eye level, the lighting is good,
                            and your microphone is properly placed.
                        </li>
                        <li>
                            <strong>Check Your Equipment:</strong> Do a test
                            recording to check sound levels, lighting, and video
                            quality.
                        </li>
                        <li>
                            <strong>Be Natural:</strong> Speak clearly at a
                            moderate pace. Be yourself to keep the content
                            engaging.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Step 4: Edit Your Recording</h3>
                    <h4>Use Editing Software:</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Video:</strong> Programs like iMovie, Adobe
                            Premiere Pro etc.
                        </li>
                        <li>
                            <strong>Audio:</strong> Software like Audacity or
                            Adobe Audition.
                        </li>
                    </ul>

                    <h4>Editing:</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            Cut Unnecessary Parts: Remove any mistakes, long
                            pauses, or off-topic sections.
                        </li>
                        <li>
                            Add Visuals and Enhancements: Insert slides, images,
                            and captions to emphasize key points.
                        </li>
                        <li>
                            Ensure Quality: Check for clear audio, good visual
                            quality, and smooth transitions.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Step 5: Save and Export from the Editing Software</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Format Your File:</strong> Ensure your video
                            is in a format compatible with platforms (e.g., MP4,
                            MP3).
                        </li>
                        <li>
                            <strong>Add Metadata:</strong> Include a title,
                            description, and keywords to make your content
                            searchable.
                        </li>
                        <li>
                            <strong>Organize Content:</strong> Place your
                            video/audio in the appropriate section.
                        </li>
                        <li>
                            <strong>Test Playback:</strong> Finally, preview
                            your video before uploading.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Multimedia Quality Standards</h3>

                    <h4>Video Quality Standards</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Resolution:</strong>
                        </li>
                        <ul style={{ marginLeft: '20px' }}>
                            <li>
                                HD (High Definition): 1280 x 720 pixels (720p) –
                                suitable for most online platforms.
                            </li>
                            <li>
                                Full HD: 1920 x 1080 pixels (1080p) –
                                recommended for a sharper and more professional
                                look.
                            </li>
                        </ul>
                        <li>
                            <strong>Frame Rate:</strong> 24-30 frames per second
                            (fps) – smooth and cinematic.
                        </li>
                        <li>
                            <strong>High Motion:</strong> 60 fps – for content
                            involving fast movement (e.g., demonstrations,
                            sports).
                        </li>
                        <li>
                            <strong>Lighting:</strong> Use natural light where
                            possible, positioning yourself facing a window.
                            Avoid backlighting; the light should come from in
                            front of you, not behind.
                        </li>
                        <li>
                            <strong>Camera:</strong> Use a high-quality webcam
                            or DSLR/mirrorless camera or a good mobile phone
                            that supports the desired resolution.
                        </li>
                        <li>
                            <strong>Background:</strong> Choose a clean,
                            uncluttered background. Consider using a virtual
                            background or green screen if appropriate.
                        </li>
                        <li>
                            <strong>Composition:</strong> Follow the rule of
                            thirds to make the video visually appealing.
                        </li>
                    </ul>

                    <h4>Audio Quality Standards</h4>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Microphone:</strong> Use a high-quality
                            external microphone (e.g., USB condenser mic). Place
                            the microphone close to you but out of the camera
                            frame.
                        </li>
                        <li>
                            <strong>Environment:</strong> Record in a quiet,
                            echo-free room. Use sound-absorbing materials like
                            carpets, curtains, and acoustic panels.
                        </li>
                        <li>
                            <strong>Audio Settings:</strong> Sample Rate: 44.1
                            kHz or 48 kHz. Bit Depth: 16-bit or 24-bit for
                            better dynamic range.
                        </li>
                        <li>
                            <strong>Voice Clarity:</strong> Speak clearly and at
                            a moderate pace. Stay close to the microphone.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Editing and Post-Production</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            Use editing software like Adobe Premiere Pro, Final
                            Cut Pro, or DaVinci Resolve. For simpler needs,
                            tools like iMovie or Audacity can be used.
                        </li>
                        <li>
                            Remove unnecessary parts to keep the content
                            concise.
                        </li>
                        <li>
                            Add transitions and effects to enhance clarity and
                            maintain a smooth flow.
                        </li>
                        <li>
                            Use audio enhancement tools to remove background
                            noise and make your voice sound clearer.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Export Settings</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            Export your file in MP4 format with H.264 codec for
                            high quality and compatibility.
                        </li>
                        <li>
                            Audio should be exported as AAC format with a
                            bitrate of at least 128 kbps.
                        </li>
                    </ul>
                </section>
            </div>
        </>
    )
}
