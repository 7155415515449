import React from 'react'
import { CreateCourse } from './CreateCourses/createCourse'
import { PreviewCourse } from './CreateCourses/previewCourse/index'
import { EditPreview } from './CreateCourses/previewCourse/editPreview'
import { CreateModule } from './CreateModule/createModule'
import { CreateLesson } from './CreateLesson/createLesson'
// import { CreateLesson } from './CreateLesson/index'
import { Allinone } from './ReviewCourse/allInOne'
import './styles.scss'

export function DeliveryComp() {
    const loc = document.location.pathname;

    switch (loc) {
        case '/tutor/create-course':
            return <CreateCourse title="Create Course" />
        case '/tutor/create-module':
            return <CreateModule />
        case '/tutor/create-lesson':
            return <CreateLesson />
        case '/tutor/create-products':
            return <Allinone />
        case '/tutor/preview-course':
            return <PreviewCourse />
        case '/tutor/edit-course':
            return <EditPreview />
        default:
            console.log('No way')
            break;
    }
}