import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { DashboardLayout } from '../../components'
import { useInstructorData, useAuth } from '../../hooks'
// import { useGeoLocation } from '../../utils'
import { instructorDashboardRoutes } from '../../constants'
import { NotFound } from './NotFound'
import { InstructorDB } from './Home'
import { Settings } from './Settings'
import { InstructorCourses } from './ManageCourses'
import { DeliveryComp } from './CreateEditCourses'
import { InstructorPayout } from './instructorPayout/InstructorPayout'
// import { Upload } from './Upload'
import { AddingYourLessonsIntoModules, BestPracticesForCreatingQuiz, PlanningYourCourse, RecordingLessonsAndMediaQualityStandard, UnderstandingOurCourseStructure, UpdateOfCourseContent, UploadingCourses, UploadingYourLessons } from './InstrucGuides'
import { setTasks } from './SetTasks'
import { EditLesson } from './CreateEditCourses/EditLesson/index.jsx'
import { EditModule } from './CreateEditCourses/EditModule'
import { ReviewCourse } from './CreateEditCourses/ReviewCourse'
import { Successpage } from './CreateEditCourses/SuccessMessage/index.jsx'
import { QuizCreationPage } from './Quiz/QuizCreationPage'
import { CourseDetails } from '../userDashboard/CourseDetails'
import PaymentDashboard from './PaymentDashboard/PaymentDashboard'
// import { StripeSupported } from '../landing/pricing/Payment/currencySymbols'

const routes = [
    {
        path: '/tutor',
        name: 'Dashboard',
        exact: true,
        component: InstructorDB,
    },
    {
        path: '/tutor/success',
        name: 'Success Message',
        exact: true,
        component: Successpage,
    },
    {
        path: '/tutor/create-course',
        name: 'Create Course',
        exact: true,
        component: DeliveryComp,
    },

    {
        path: '/tutor/edit-course',
        name: 'Edit Course',
        exact: true,
        component: DeliveryComp,
    },

    {
        path: '/tutor/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/tutor/courses',
        name: 'Courses',
        exact: true,
        component: InstructorCourses,
    },
    {
        path: '/tutor/tasks',
        name: 'Manage Tasks',
        component: setTasks,
    },
    {
        path: '/tutor/revenue',
        name: 'Revenue/Payment',
        component: PaymentDashboard,
    },
    {
        path: '/tutor/create-lesson',
        name: 'Create Lesson',
        component: DeliveryComp,
    },

    {
        path: '/tutor/edit-lesson',
        name: 'Edit Lesson',
        component: EditLesson,
    },
    {
        path: '/tutor/review-course',
        name: 'Review course',
        component: ReviewCourse,
    },
    {
        path: '/tutor/Preview-course',
        name: 'Preview course',
        component: DeliveryComp,
    },
    // Add new pages aboved this or they will not work ↓↓
    {
        path: '/tutor/create-module',
        name: 'Create Module',
        exact: true,
        component: DeliveryComp,
    },
    {
        path: '/tutor/edit-module',
        name: 'Edit Module',
        component: EditModule,
    },
    {
        path: '/tutor/apageCreation',
        name: 'Create Complete Course',
        component: DeliveryComp,
    },
    // {
    //     path: '/tutor/upload',
    //     name: 'Create Complete Course',
    //     component: Upload,
    // },
    {
        path: '/tutor/stats',
        name: 'test',
        exact: true,
    },
    {
        path: '/tutor/create-quiz',
        name: 'Create quiz',
        exact: true,
        component: QuizCreationPage,
    },
    {
        path: '/tutor/preview-quiz/:id',
        name: 'Preview quiz',
        exact: true,
        component: CourseDetails,
    },
    {
        path: '/tutor/payout',
        name: 'Instructor payout',
        exact: true,
        component: InstructorPayout,
    },
    {
        path: '/tutor/guide/AddingYourLessonsIntoModules',
        name: 'Instructor Guide',
        exact: true,
        component: AddingYourLessonsIntoModules,
    },
    {
        path: '/tutor/guide/BestPracticesForCreatingQuiz',
        name: 'Instructor Guide',
        exact: true,
        component: BestPracticesForCreatingQuiz,
    },
    {
        path: '/tutor/guide/PlanningYourCourse',
        name: 'Instructor Guide',
        exact: true,
        component: PlanningYourCourse,
    },
    {
        path: '/tutor/guide/RecordingLessonsAndMediaQualityStandard',
        name: 'Instructor Guide',
        exact: true,
        component: RecordingLessonsAndMediaQualityStandard,
    },
    {
        path: '/tutor/guide/UnderstandingOurCourseStructure',
        name: 'Instructor Guide',
        exact: true,
        component: UnderstandingOurCourseStructure,
    },
    {
        path: '/tutor/guide/UpdateOfCourseContent',
        name: 'Instructor Guide',
        exact: true,
        component: UpdateOfCourseContent,
    },
    {
        path: '/tutor/guide/UploadingCourses',
        name: 'Instructor Guide',
        exact: true,
        component: UploadingCourses,
    },
    {
        path: '/tutor/guide/UploadingYourLessons',
        name: 'Instructor Guide',
        exact: true,
        component: UploadingYourLessons,
    },
    {
        path: '',
        name: 'Not Found',
        exact: true,
        component: NotFound,
    },
]

export const InstructorDashboard = () => {
    const {
        state: { data }
    } = useAuth()

    const {
        getInstructorID
    } = useInstructorData()

    useEffect(() => {
        const isInstructor = () => {
            try {
                getInstructorID(data?.id)
            } catch (error) {
                console.error(error.message)
            }
        }
        isInstructor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])

    // const { location } = useGeoLocation()
    // const { addUserLocation } = useLocation()

    // Use effect to set user location when the page is loaded for the first time.
    // useEffect(() => {
    //     const setUsersLocation = () => {
    //         const allowedCountries = []
    //         for (let i = 0; i < StripeSupported.length; i++) {
    //             allowedCountries.push(StripeSupported[i].countryCode)
    //         }
    //         const storedCountryDetail = JSON.parse(
    //             localStorage.getItem('zustech:user-location')
    //         )
    //         if (location !== null) {
    //             if (
    //                 storedCountryDetail === null ||
    //                 storedCountryDetail.city !== location.city
    //             ) {
    //                 localStorage.setItem(
    //                     'zustech:user-location',
    //                     JSON.stringify(location)
    //                 )
    //                 if (allowedCountries.includes(location.country_code)) {
    //                     localStorage.setItem('currentCountry', 'GB')
    //                 } else {
    //                     localStorage.setItem('currentCountry', 'NG')
    //                 }
    //                 addUserLocation(location)
    //             } else {
    //                 addUserLocation(storedCountryDetail)
    //             }
    //         } else {
    //             // setUsersLocation()
    //             console.log('Yet to query user location')
    //         }
    //     }
    //     setUsersLocation()

    //     return () => {
    //         localStorage.removeItem('zustech:user-location')
    //         addUserLocation(null)
    //     }

    //     // eslint-disable-next-line
    // }, [location])

    return (
        <DashboardLayout routeList={instructorDashboardRoutes}>
            <Switch>
                {routes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            // path={`/tutor${route.path}`}
                            {...route}
                        />
                    )
                })}
            </Switch>
        </DashboardLayout>
    )
}