import React from 'react'
import ReactPlayer from 'react-player/lazy'
// import AddLessonsImage from './assets/Add lessons to modules.svg'

export const AddingYourLessonsIntoModules = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>
                Adding Your Lessons Into Modules
            </h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>
        </>
    )
}
