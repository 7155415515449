import {
    AUTH_START,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    GET_USER_START,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    REDIRECT,
    DELETE_USER_START,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    LOGIN_ADMIN_SUCCESS,
    LOGIN_ADMIN_FAIL,
    ACTIVE_SSO,
    CLOSE_SSO_MODAL,
    SET_USER_ACTIVE_SUBSCRIPTION,
    SET_USER_COURSE_SUBSCRIPTIONS
} from './types'
import {
    registerUser,
    loginUser,
    logoutUser,
    getUser,
    deleteUser,
} from '../../../services'
import { openNotification, setAuthToken } from '../../../utils'
import { loginAdmin } from '../../../services/admin.service'

export const register = (values) => async (dispatch) => {
    try {
        dispatch({ type: AUTH_START })
        const data = await registerUser(values)
        console.log(data)
        dispatch({ type: REGISTER_USER_SUCCESS, payload: data })
        dispatch({ type: SET_USER_ACTIVE_SUBSCRIPTION, payload: data.subcriptions.generalSub })
        dispatch({ type: SET_USER_COURSE_SUBSCRIPTIONS, payload: data.subcriptions.coursesSub })
        // setAuthToken(values.rememberMe, data?.accessToken)
        dispatch({ type: REDIRECT, payload: '/account/login' })
        openNotification({
            type: 'success',
            title: 'Sign Up',
            message: data?.message,
        })
    } catch (error) {
        dispatch({ type: REGISTER_USER_FAIL, payload: error })
        openNotification({
            type: 'error',
            title: 'Sign Up',
            message: error?.message,
        })
    }
}

export const closeSignInModal = () => async (dispatch) => {
    dispatch({ type: CLOSE_SSO_MODAL, payload: false })
}

// export const priceRegister = (values) => async (dispatch) => {
//     try {
//         dispatch({ type: AUTH_START })
//         const data = await signUp(values)
//         dispatch({ type: REGISTER_USER_SUCCESS, payload: data?.user })
//         openNotification({
//             type: 'success',
//             title: 'Sign Up',
//             message: 'Check your email address to verify Zustech account',
//         })
//         dispatch({ type: AUTH_START })
//         const val = await loginUser(values)
//         dispatch({ type: LOGIN_USER_SUCCESS, payload: val })
//         // setAuthToken(values.rememberMe, data?.accessToken)
//         // dispatch({ type: REDIRECT, payload: '/me' })
//         dispatch({ type: REDIRECT, payload: '/me/settings/subscription' })
//         // openNotification({
//         //     type: 'success',
//         //     title: 'Sign Up',
//         //     message: 'Check your email address to verify Zustech account',
//         // })
//     } catch (error) {
//         dispatch({ type: REGISTER_USER_FAIL, payload: error })
//         openNotification({
//             type: 'error',
//             title: 'Sign Up',
//             message: error?.message,
//         })
//     }
// }

export const login = (values) => async (dispatch) => {
    try {
        dispatch({ type: AUTH_START })
        const data = await loginUser(values)
        dispatch({ type: LOGIN_USER_SUCCESS, payload: data })
        setAuthToken(values.rememberMe, data?.accessToken)
        dispatch({ type: SET_USER_ACTIVE_SUBSCRIPTION, payload: data.subscriptions.generalSub })
        dispatch({ type: SET_USER_COURSE_SUBSCRIPTIONS, payload: data.subscriptions.coursesSub })
        dispatch({ type: REDIRECT, payload: '/me' })
    } catch (error) {
        if (error.message === 'Please you need to logout of any other devices') {
            dispatch({ type: ACTIVE_SSO, payload: true })
        } else {
            dispatch({ type: LOGIN_USER_FAIL, payload: error })
            openNotification({
                type: 'error',
                title: 'Login',
                message: error?.message,
            })
        }
    }
}

export const getProfile = () => async (dispatch) => {
    try {
        dispatch({ type: GET_USER_START })
        const data = await getUser()
        dispatch({ type: GET_USER_SUCCESS, payload: data })
        dispatch({ type: SET_USER_ACTIVE_SUBSCRIPTION, payload: data.subscriptions.generalSub })
        dispatch({ type: SET_USER_COURSE_SUBSCRIPTIONS, payload: data.subscriptions.coursesSub })
    } catch (error) {
        dispatch({ type: GET_USER_FAIL, payload: error })
        openNotification({
            type: 'error',
            title: 'Profile',
            message: error?.message,
        })
    }
}

export const logout = (value) => async (dispatch) => {
    try {
        dispatch({ type: AUTH_START })
        const response = await logoutUser({ 'email': value })
        if (response.status) {
            openNotification({
                type: 'success',
                title: 'Logout',
                message: 'User successfully logged out',
            })
            localStorage.clear()
            dispatch({ type: LOGOUT_USER_SUCCESS })
            dispatch({ type: REDIRECT, payload: '/account/login' })
        }
    } catch (error) {
        dispatch({ type: LOGOUT_USER_FAIL, payload: error })
        openNotification({
            type: 'error',
            title: 'Logout',
            message: error.message,
        })
    }
}

export const deleteAccount = (email) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_USER_START })
        await deleteUser(email)
        dispatch({ type: DELETE_USER_SUCCESS })
        openNotification({
            type: 'success',
            title: 'Delete Account',
            message: 'Your acccount has been successfully deleted!',
        })
    } catch (error) {
        dispatch({ type: DELETE_USER_FAIL, payload: error })
        openNotification({
            type: 'error',
            title: 'Delete Account',
            message: error.message,
        })
    }
}

export const adminLogin = (values) => async (dispatch) => {
    try {
        dispatch({ type: AUTH_START })
        const data = await loginAdmin(values)
        dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: data })
        setAuthToken(values.rememberMe, data?.accessToken)
        dispatch({ type: REDIRECT, payload: '/admin' })
        openNotification({
            type: 'success',
            title: 'Login',
            message: data?.message,
        })
    } catch (error) {
        dispatch({ type: LOGIN_ADMIN_FAIL, payload: error })
        openNotification(
            {
                type: 'error',
                title: 'Login',
                message: error?.message,
            },
            5.0
        )
    }
}