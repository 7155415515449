import { useState } from 'react'
// import { Collapse } from 'antd';
import {
    Accordion,
} from 'react-accessible-accordion'
import { AccordionComponent } from '../components/Accordion'
import StudentContactExplore from './StudentExplore'
import InstructorContactExplore from './InstructorExplore'
import { generalFAQ } from '../StudentSupport/component/constant'
import Search from '../../../../components/Searchbar/searchbar'
import imageTop from './assets/Rectangle-138.svg'
import './contact.scss'
import 'react-accessible-accordion/dist/fancy-example.css'

export const ContactUs = () => {
    const [isStudentContact, setIsStudentContact] = useState(true)
    const [isInstructorContact, setIsInstructorContact] = useState(false)

    function handleClick() {
        setIsStudentContact(!isStudentContact)
        setIsInstructorContact(!isInstructorContact)
    }

    return (
        <div className="contact">
            <div className="wrapper">
                {/* TOP SECTION */}
                <section className="top-section">
                    <div className="img-container">
                        <img src={imageTop} className="image-top" alt="" />
                        <h3 className="top-text">We are here to help you!</h3>
                    </div>
                    <div className="search-box">
                        {/* <input placeholder="Search articles" name="search-article" id="search-article" className="search-article" /> */}
                        <Search type="article" />
                    </div>
                    <div className="btn-faq">
                        <button
                            type="button"
                            style={{
                                backgroundColor: isStudentContact
                                    ? '#3A86FF'
                                    : 'white',
                                color: isStudentContact ? 'white' : '#0837E5',
                            }}
                            className="btn-student btn"
                            onClick={handleClick}
                        >
                            Learners
                        </button>
                        <button
                            type="button"
                            style={{
                                backgroundColor: isStudentContact
                                    ? 'white'
                                    : '#3A86FF',
                                color: isStudentContact ? '#0837E5' : 'white',
                            }}
                            className="btn-instructor btn"
                            onClick={handleClick}
                        >
                            Instructors
                        </button>
                    </div>
                </section>

                {/* BOTTOM SECTION EXPLORE */}
                {isStudentContact ? (
                    <StudentContactExplore />
                ) : (
                    <InstructorContactExplore />
                )}

                {/* FAQ */}
                <section className="faq-container">
                    <div className="question-faq">
                        <h3 className="faq-head">Frequently Asked Questions</h3>
                        <div className="questions-container">
                            <Accordion>
                                {generalFAQ.map((faq) => {
                                    return (
                                        <AccordionComponent
                                            key={faq.id}
                                            question={faq.question}
                                            answers={faq.response}
                                        />
                                    )
                                })}
                            </Accordion>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}