import React from 'react'
import ReactPlayer from 'react-player/lazy'

export const PlanningYourCourse = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>Planning Your Course</h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>

            <div>
                <p style={{ padding: '30px 0px', }}>
                    Here, We are going to give you the best tips on how to plan
                    your course.
                </p>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Understand Your Learners</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Conduct a Needs Analysis:</strong> Assess
                            the learners' current knowledge, skills, and
                            learning preferences.
                        </li>
                        <li>
                            <strong>Demographics and Context:</strong> Consider
                            age, background, learning environment, and any
                            special needs.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Defining Learning Objectives</h3>
                    <p>
                        Before creating course content, define what you want
                        your students to learn. Clear and measurable objectives
                        will suffice in determining the structure and content of
                        your lessons and modules. Employ a SMART framework that
                        ensures objectives are Specific, Measurable, Achievable,
                        Relevant, and Time-bound.
                    </p>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Creating a Course Outline</h3>
                    <p>
                        Outline the main topics your course will cover. This
                        will serve as a roadmap for building your modules.
                        Consider sequencing the topics logically, ensuring a
                        smooth progression from one module to the next.
                    </p>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Course Titles and Descriptions</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Titles:</strong> Keep them concise and
                            descriptive.
                        </li>
                        <li>
                            <strong>Descriptions:</strong> Provide a brief
                            overview of the module's content and its learning
                            objectives.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Structure Content for Clarity and Engagement</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            Use headings, subheadings, bullet points, and
                            paragraphs to break up text.
                        </li>
                        <li>
                            Incorporate summaries and key takeaways to reinforce
                            learning.
                        </li>
                        <li>
                            <strong>Chunk Content:</strong> Break down
                            information into manageable pieces.
                        </li>
                        <li>
                            <strong>Progressive Difficulty:</strong> Start with
                            basic concepts and gradually move to more complex
                            topics.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Incorporate Active Learning Strategies</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>Use quizzes and simulations to engage learners.</li>
                        <li>
                            Provide additional resources such as readings,
                            videos, and tutorials (support materials).
                        </li>
                        <li>Design Assessments to Help Measure Learning.</li>
                        <li>
                            Formative Assessments: Include quizzes, reflections,
                            and practice tests to provide ongoing feedback.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Enhancing Learning with Multimedia</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            Use multimedia to make your content more engaging.
                            Videos can demonstrate concepts, while interactive
                            elements like quizzes can reinforce learning.
                        </li>
                        <li>Using Visual Aids Effectively.</li>
                        <li>
                            Charts, diagrams, and images can help explain
                            complex ideas and keep students engaged.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Accessibility and Inclusivity</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>
                                Universal Design for Learning (UDL):
                            </strong>{' '}
                            Implement principles that accommodate diverse
                            learners.
                        </li>
                        <li>
                            <strong>Accessible Content:</strong> Ensure all
                            materials meet accessibility standards (e.g., alt
                            text for images, and captions for videos).
                        </li>
                        <li>
                            <strong>Cultural Relevance:</strong> Design content
                            that is culturally responsive and inclusive.
                        </li>
                    </ul>
                </section>

                <section style={{ marginBottom: '20px' }}>
                    <h3>Leverage Current Research</h3>
                    <ul style={{ marginLeft: '20px' }}>
                        <li>
                            <strong>Stay Updated:</strong> Regularly review
                            academic journals, attend conferences, and
                            participate in professional development.
                        </li>
                        <li>
                            <strong>Facts-Based Practices:</strong> Integrate
                            strategies and techniques that have been proven
                            effective through research.
                        </li>
                        <li>
                            <strong>Collaborate with Experts:</strong> Work with
                            educational researchers and instructional designers
                            to enhance your course.
                        </li>
                    </ul>
                </section>
            </div>
        </>
    )
}
