import { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { Modal, Button, Switch, Radio, Input, Space } from 'antd'
import { RequestLoader } from '../../../../../components/Loading'
import { openNotification } from '../../../../../utils'
import {
    cancelSubscription,
    getPlanById,
    findSubscriptionByUserId,
} from '../../../../../services'
import {
    useAuth
} from '../../../../../hooks'
import { Payment } from '../../Payment/index'
import Onblue from '../../../../../assets/icons/Onblue.svg'
import './activeSubCard.scss'

export const ActiveplanCard = ({ subPlans }) => {
    const { state: { data, activeSub } } = useAuth()
    // const activeSubInfo = state.genaralSub
    const [openCardModal, setOpenCardModal] = useState(false)
    const [newPlanId, setNewPlanId] = useState()
    const [COPFirstModal, setCOPFirstModal] = useState(false)
    const [openPaymentModal, setOpenpaymentModal] = useState(false)
    const [firstCancelPlanModal, setFirstCancelPlanModal] = useState(false)
    const [secondCancelPlanModal, setSecondCancelPlanModal] = useState(false)
    const [thirdCancelPlanModal, setThirdCancelPlanModal] = useState(false)
    const [fourthCancelPlanModal, setFourthCancelPlanModal] = useState(false)
    const [cancellingSub, setCancellingSub] = useState(false)
    const [offerAccepted, setOfferAccepted] = useState(false)
    const [typedReason, setTypedReason] = useState('')
    const [cancelReason, setCancelReason] = useState('')
    const [activeSubInfo, setActiveSubInfo] = useState({})
    const [isUserSubscribed, setIsUserSubscribed] = useState()

    const { TextArea } = Input
    // const history = useHistory()

    async function endplan(planId, reason) {
        setCancellingSub(true)
        try {
            const values = {
                subscriptionId: planId,
                reason: reason,
            }
            const res = await cancelSubscription(values)
            if (res.message === 'Success') {
                // set state for data
                setOpenCardModal(false)
                setThirdCancelPlanModal(false)
                setFourthCancelPlanModal(true)
                openNotification({
                    type: 'success',
                    title: 'Subscription Cancellation',
                    message: res.message,
                })
            }
        } catch (err) {
            console.error(err)
            openNotification({
                type: 'error',
                title: 'Subscription Cancellation',
                message: err.message,
            })
        }
    }

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    const getActiveSubscription = async (activeSub) => {
        try {
            if (activeSub) {
                const response = await getPlanById(activeSub.planId)
                if (response) {
                    const subInfo = {
                        name: response.name,
                    }
                    switch (activeSub.subscriptionStatus) {
                        case 1:
                            subInfo['status'] = 'Active'
                            subInfo['color'] = '#3a86ff'
                            break
                        case 2:
                            subInfo['status'] = 'Inactive'
                            subInfo['color'] = '#4f4f4f'
                            break
                        case 3:
                            subInfo['status'] = 'Successfully'
                            subInfo['color'] = '#46d5b3'
                            break
                        case 4:
                            subInfo['status'] = 'Cancelled'
                            subInfo['color'] = '#ffcc00'
                            break
                        case 5:
                            subInfo['status'] = 'Expired'
                            subInfo['color'] = '#eb5757'
                            break
                        default:
                            subInfo['status'] = 'Inactive'
                            subInfo['color'] = '#4f4f4f'
                            break
                    }
                    subInfo['key'] = activeSub.id
                    subInfo['planId'] = activeSub.planId
                    subInfo['startDate'] = getReadableDate(
                        activeSub.subscription_start_timestamp
                    )
                    subInfo['endDate'] = getReadableDate(
                        activeSub.subscription_end_timestamp
                    )
                    setActiveSubInfo(subInfo)
                    setIsUserSubscribed(true)
                } else {
                    setIsUserSubscribed(false)
                    setActiveSubInfo({})
                }
            } else {
                setIsUserSubscribed(false)
                setActiveSubInfo({
                    name: 'No Active Plan',
                    startDate: '--/--/--',
                    endDate: '--/--/--',
                    status: 'no plan',
                })
            }
        } catch (err) {
            console.error(err.message)
        }
    }

    const fetchUserActiveSub = async () => {
        try {
            const result = await findSubscriptionByUserId(data.id)
            if (result) {
                getActiveSubscription(result[0])
            } else {
                setIsUserSubscribed(false)
                setActiveSubInfo({
                    name: 'No Active Plan',
                    startDate: '--/--/--',
                    endDate: '--/--/--',
                    status: 'no plan'
                })
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    useEffect(() => {
        if (activeSub) {
            getActiveSubscription(activeSub)
        } else {
            fetchUserActiveSub()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSub])

    return (
        <>
            <div className="activePlan_cont">
                {isUserSubscribed ? (
                    <h2 data-testid="card-header">
                        Active Plan <span>({activeSubInfo.name})</span>
                    </h2>
                ) : (
                    <h2>{activeSubInfo.name}</h2>
                )}
                <div className="main_view">
                    <div className="start_date_cont">
                        <span className="date_label">Start: </span>
                        <span className="mainDateCont">
                            {activeSubInfo.startDate}
                        </span>
                    </div>
                    <div className="renewal_date_cont">
                        <span className="date_label">
                            {['Active', 'no plan'].includes(
                                activeSubInfo.status
                            )
                                ? 'Renews'
                                : 'Expires'}
                            :{' '}
                        </span>
                        <span className="mainDateCont">
                            {activeSubInfo.endDate}
                        </span>
                    </div>
                </div>
                {activeSubInfo.status === 'Active' && (
                    <div
                        data-testid="cancel_planBtn"
                        className="cancel_btn"
                        id="cancel_planBtn"
                        onClick={() => {
                            setOpenCardModal(true)
                        }}
                    >
                        Cancel plan
                    </div>
                )}
                {activeSubInfo.status === 'Cancelled' && (
                    <p data-testid="cancelled-sub" id="cancelled_sub_info">
                        You cancelled your current plan. Choose a{' '}
                        <span>NEW PLAN</span> below
                    </p>
                )}
                {!isUserSubscribed && (
                    <p className="option_info">Choose a plan below</p>
                )}
            </div>
            {/* ACTIVE PLAN CARD MODAL */}
            <Modal
                open={openCardModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setOpenCardModal(false)
                }}
                width={500}
                className="activePlanCardModal"
                id="activePlanCardModal"
                data-testid="activePlanCardModal"
            >
                <div className="card_cont" id="current_plan_cont">
                    <h3>Subscription</h3>
                    <div className="main_view">
                        <section className="main_info">
                            <div className="primary_info">
                                <h2>
                                    {activeSubInfo.name} <span>Plan</span>
                                </h2>
                                <h6>
                                    Status:{' '}
                                    <span
                                        style={{
                                            fontSize: '1em',
                                            color: `${activeSubInfo.color}`,
                                        }}
                                    >
                                        {activeSubInfo.status}
                                    </span>
                                </h6>
                            </div>
                            <div className="dates">
                                <p>
                                    <span className="start">Start: </span>
                                    <span>{activeSubInfo.startDate}</span>
                                </p>
                                <p>
                                    <span className="expire">
                                        {['Active', 'no plan'].includes(
                                            activeSubInfo.status
                                        )
                                            ? 'Renews'
                                            : 'Expires'}
                                        :{' '}
                                    </span>
                                    <span>{activeSubInfo.endDate}</span>
                                </p>
                            </div>
                        </section>
                        <section className="upgrade">
                            <p>
                                Change from a {activeSubInfo.name} plan to a{' '}
                                {activeSubInfo.planId !== 2
                                    ? 'Monthly'
                                    : 'Annual'}{' '}
                                plan
                            </p>
                            <Button
                                type="primary"
                                data-testid="change-plan"
                                id="change-plan"
                                // disabled
                                style={{
                                    borderRadius: '8px',
                                }}
                                onClick={() => {
                                    setCOPFirstModal(true)
                                    setOpenCardModal(false)
                                    if (activeSubInfo.planId !== 2) {
                                        setNewPlanId(2)
                                    } else {
                                        setNewPlanId(
                                            subPlans[subPlans.length - 1].id
                                        )
                                    }
                                }}
                            >
                                Change plan
                            </Button>
                        </section>
                        <section className="renewal">
                            <p>Automatically renew your plan</p>
                            <div className="CTA">
                                <Switch data-testid="switch" id="switch" defaultChecked />
                            </div>
                        </section>
                    </div>
                    <div
                        className="cancel_btn"
                        id="cancel_planBtn"
                        data-testid="cancel_planBtnII"
                        onClick={() => {
                            setFirstCancelPlanModal(true)
                            setOpenCardModal(false)
                            // if (activeSubInfo.planId !== 1) {
                            //     setFirstCancelPlanModal(true)
                            // } else {
                            //     openNotification({
                            //         title: 'Plan Cancellation',
                            //         type: 'error',
                            //         message: "Free Plan can't be cancelled",
                            //     })
                            // }
                        }}
                    >
                        Cancel plan
                    </div>
                </div>
            </Modal>
            {/* CHANGE OF PLAN FIRST MODAL */}
            <Modal
                open={COPFirstModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setCOPFirstModal(false)
                }}
                width={500}
                id="ChangeOfPlanFirstModal"
                className="ChangeOfPlanFirstModal"
                data-testid="ChangeOfPlanFirstModal"
            >
                <h2 data-testid="COPTitle" id="COPTitle" className="title">Change to Annual Plan</h2>
                {
                    // check for newPlanId in Plan data, then render it
                    newPlanId !== undefined &&
                        subPlans
                            .filter((plan) => plan.id === newPlanId)
                            .map((newplan) => (
                                <div id={`card-${newplan.id}`} key={newplan.id} className="product_cont">
                                    <h3>{newplan.name}</h3>
                                    {/* <p>{newplan.sellPoint}</p> */}
                                    <p>Most Popular</p>
                                    <ul id="benefit-list" data-testid="benefit-list">
                                        {newplan.benefits.map((itm, idx) => (
                                            <li key={idx}>
                                                <div>
                                                    <img src={Onblue} alt="" />
                                                </div>
                                                <p>{itm}</p>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        type="primary"
                                        data-testid="COP-cta"
                                        id="COP-cta"
                                        onClick={() => {
                                            setCOPFirstModal(false)
                                            setOpenpaymentModal(true)
                                            // history.push(`payment/${newPlanId}`)
                                        }}
                                    >
                                        Get Started
                                    </Button>
                                </div>
                            ))
                }
            </Modal>
            {/* Payment modal */}
            <Modal
                open={openPaymentModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setOpenpaymentModal(false)
                }}
                width={600}
                closeIcon={null}
                id="paymentModal"
                className="paymentModal"
                data-testid="paymentModal"
            >
                <Payment
                    paymentPurpose="COP" // change of plan
                    plnId={newPlanId}
                    prevPlanId={activeSubInfo.key}
                    offerAccepted={offerAccepted}
                />
            </Modal>
            {/* CANCEL PLAN FIRST MODAL */}
            <Modal
                open={firstCancelPlanModal}
                // open={true}
                centered
                footer={null}
                closeIcon={null}
                closable={false}
                // onCancel={() => { setFirstCancelPlanModal(false) }}
                width={500}
                id="CancelPlanFirstModal"
                className="CancelPlanFirstModal"
                data-testid="CancelPlanFirstModal"
            >
                <h2 data-testid="ftM-title" id="ftM-title" className="title">
                    Are you sure you want to cancel your plan?
                </h2>
                <p id="modalInfo">
                    You will lose access to our amazing courses and the
                    wonderful features we offer when the current plan expires.
                </p>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em',
                    }}
                >
                    <Button
                        onClick={() => {
                            setSecondCancelPlanModal(true)
                            setFirstCancelPlanModal(false)
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px',
                        }}
                        data-testid="main-cancelBtn"
                        id="main-cancelBtn"
                    >
                        Cancel Plan
                    </Button>
                    <Button
                        onClick={() => {
                            setFirstCancelPlanModal(false)
                        }}
                        type="primary"
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px',
                        }}
                        id="backBtn"
                    >
                        Go Back
                    </Button>
                </div>
            </Modal>
            {/* SECOND CANCEL PLAN MODAL */}
            <Modal
                open={secondCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setSecondCancelPlanModal(false)
                    setCancelReason('')
                    setTypedReason('')
                }}
                width={500}
                id="CancelPlanSecondModal"
                data-testid="CancelPlanSecondModal"
                className="CancelPlanSecondModal"
            >
                <h2 data-testid="scM-title" id="scM-title" className="title">
                    Why are you cancelling?
                </h2>
                <div className="options" id="options">
                    <Radio.Group
                        onChange={(e) => {
                            setCancelReason(e.target.value)
                        }}
                        value={cancelReason}
                        id="radioGroup"
                    >
                        <Space direction="vertical">
                            <Radio id="expensive" value="expensive">
                                It exceeds my budget (its too expensive for me)
                            </Radio>
                            <Radio id="schedule" value="schedule">
                                I am not using the products enough because of
                                tight and busy schedules
                            </Radio>
                            <Radio id="discountEnticed" value="discountEnticed">
                                I only signed up to use the initial introductory
                                discount
                            </Radio>
                            <Radio id="notValueable" value="notValueable">
                                I am not getting equal value for my money
                            </Radio>
                            <Radio id="interestChanged" value="interestChanged">
                                My interests have changed
                            </Radio>
                            <Radio id="others" value="others">Others</Radio>
                        </Space>
                    </Radio.Group>
                    {cancelReason === 'others' && (
                        <TextArea
                            id="otherReason"
                            maxLength={200}
                            showCount
                            style={{
                                height: 120,
                                marginBottom: 24,
                                marginTop: 20,
                            }}
                            placeholder="Tell us why you are cancelling"
                            value={typedReason}
                            onChange={(e) => {
                                setTypedReason(e.target.value)
                            }}
                        />
                    )}
                </div>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em',
                    }}
                >
                    <Button
                        data-testid="proceed"
                        id="proceed"
                        type="primary"
                        onClick={() => {
                            if (
                                cancelReason === '' ||
                                (cancelReason === 'others' &&
                                    typedReason === '')
                            ) {
                                openNotification({
                                    title: 'Cancellation Reason',
                                    message: 'Choose a reason for cancelling',
                                    type: 'error',
                                })
                            } else {
                                setSecondCancelPlanModal(false)
                                setThirdCancelPlanModal(true)
                            }
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px',
                        }}
                    >
                        Proceed
                    </Button>
                </div>
            </Modal>
            {/* THIRD CANCLE PLAN MODAL */}
            <Modal
                open={thirdCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setThirdCancelPlanModal(false)
                }}
                width={500}
                id="CancelPlanThirdModal"
                data-testid="CancelPlanThirdModal"
                className="CancelPlanThirdModal"
            >
                {!cancellingSub && (
                    <>
                        <h2 id="thM-title" data-testid="thM-title" className="title">
                            We want to help you to stay!
                        </h2>
                        <p id="discountInfo">
                            We're{' '}
                            <span style={{ color: '#3a86ff' }}>
                                extending a generous 50% discount
                            </span>{' '}
                            to encourage you to stay on and achieve your
                            learning objectives..
                        </p>
                        <div
                            className="btn_cont"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                marginTop: '1em',
                            }}
                        >
                            <Button
                                id="discountAcceptBtn"
                                onClick={() => {
                                    setThirdCancelPlanModal(false)
                                    setOfferAccepted(true)
                                    setOpenpaymentModal(true)
                                    if (activeSubInfo.planId !== 2) {
                                        setNewPlanId(2)
                                    } else {
                                        setNewPlanId(
                                            subPlans[subPlans.length - 1].id
                                        )
                                    }
                                    // opem payment modal for 50% off offer
                                }}
                                type="primary"
                                style={{
                                    width: '30%',
                                    fontSize: '1em',
                                    fontWeight: '500',
                                    borderRadius: '8px',
                                }}
                            >
                                Accept Offer
                            </Button>
                            <Button
                                onClick={() => {
                                    // request to cancel plan
                                    if (typedReason === '') {
                                        endplan(activeSubInfo.key, cancelReason)
                                    } else {
                                        endplan(activeSubInfo.key, typedReason)
                                    }
                                }}
                                style={{
                                    width: '30%',
                                    fontSize: '1em',
                                    fontWeight: '500',
                                    borderRadius: '8px',
                                }}
                                data-testid="almostThere"
                                id="almostThere"
                            >
                                Cancel Plan
                            </Button>
                        </div>
                    </>
                )}
                {cancellingSub && <RequestLoader />}
            </Modal>
            {/* FOURTH CANCLE PLAN MODAL */}
            <Modal
                open={fourthCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setFourthCancelPlanModal(false)
                }}
                width={500}
                id="cancelPlanSuccessModal"
                className="cancelPlanSuccessModal"
                data-testid="CancelPlanLastModal"
            >
                <h2 id="foM-title" data-testid="foM-title" className="title">
                    We don’t want to see you go!
                </h2>
                <p id="subCancelInfo">
                    Your {activeSubInfo.name} subscription has been successfully
                    cancelled,
                </p>
                <p>
                    Your current plan will expire on [{activeSubInfo.endDate}].
                </p>
                <p id="discountInfo" style={{ color: '#3a86ff', marginBlock: '.6em' }}>
                    You can still take advantage of our 50% discount offer by
                    simply clicking the Accept offer button below.
                </p>
                <p>We look forward to welcoming you back soon!</p>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em',
                    }}
                >
                    <Button
                        id="acceptOffer"
                        onClick={() => {
                            setFourthCancelPlanModal(false)
                            setOfferAccepted(true)
                            setOpenpaymentModal(true)
                            if (activeSubInfo.planId !== 2) {
                                setNewPlanId(2)
                            } else {
                                setNewPlanId(subPlans[subPlans.length - 1].id)
                            }
                            // opem payment modal for 50% off offer
                        }}
                        type="primary"
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px',
                        }}
                    >
                        Accept Offer
                    </Button>
                    <Button
                        data-testid="finalCancelBtn"
                        id="finalCancelBtn"
                        onClick={() => {
                            // request to cancel plan
                            window.location.reload()
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px',
                        }}
                    >
                        Continue
                    </Button>
                </div>
            </Modal>
        </>
    )
}