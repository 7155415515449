import { Route, Switch } from 'react-router-dom'
import { ApplicationReview } from './InstructorApplications/ApplicationReview/ApplicationReview'
import { adminDashboardRoutes } from '../../constants'
import { AdminDashboardLayout } from '../../components'
import { NotFound } from './NotFound'
import { Settings } from '../userDashboard/Settings'
import { AdminDB } from './Home'
import { Applications } from './InstructorApplications'
import { UserReports } from './UserReports'
import { CourseReports } from './CourseReports'
import { CourseReview } from './Courses/CourseReview'
import { CourseRevieww } from './Courses/CourseRevieww'

const routes = [
    {
        path: '/admin',
        name: 'Dashboard',
        exact: true,
        component: AdminDB,
    },
    {
        path: '/admin/courses',
        name: 'Courses',
        exact: true,
        component: CourseRevieww,
    },
    {
        path: '/admin/applications',
        name: 'Applications',
        exact: true,
        component: Applications,
    },
    {
        path: '/admin/user-reports',
        name: 'User Reports',
        exact: true,
        component: UserReports,
    },
    {
        path: '/admin/applications-review',
        name: 'Applications Review',
        // exact: true,
        component: ApplicationReview,
    },
    {
        path: '/admin/courses/courses-review/:id',
        name: 'Courses',
        // exact: true,
        component: CourseReview,
    },
    {
        path: '/admin/course-reports',
        name: 'Course Reports',
        exact: true,
        component: CourseReports,
    },
    // {
    //     path: '/tutor/courses',
    //     name: 'Manage Courses',
    //     exact: true,
    //     component: ManageCourse,
    // },
    {
        path: '/admin/settings',
        name: 'Settings',
        exact: true,
        component: Settings,
    },

    // Add new pages aboved this or they will not work ↓↓
    {
        path: '',
        name: 'Not Found',
        exact: true,
        component: NotFound,
    },
]

export const AdminDashboard = () => {
    return (
        <AdminDashboardLayout routeList={adminDashboardRoutes}>
            <Switch>
                {routes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            // path={`/tutor${route.path}`}
                            {...route}
                        />
                    )
                })}
            </Switch>
        </AdminDashboardLayout>
    )
}
