import { request as RQ, clearStorage } from '../utils'

export const registerUser = async (values) => {
    try {
        const url = `/Auth/Register`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const userLocation = async (values) => {
    try {
        const url = `https://ipapi.co/json/`
        const request = await RQ
        const response = await request.get(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const signUp = async (values) => {
    try {
        const url = `/Auth/Register`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const loginUser = async (values) => {
    try {
        const url = `/Auth/Authenticate`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data?.data
    } catch (err) {
        console.log(err)
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const verifyEmail = async (values) => {
    try {
        const url = `/Auth/VerifyEmail`
        const request = await RQ
        const response = await request.get(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const softSearchCourses = async (values) => {
    try {
        const url = `/Course/SoftSearchCourses?SearchCriteria=${values}&Page=1&PageSize=20`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const resendEmail = async (values) => {
    try {
        const url = `/Auth/ResendMail`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const forgetPassword = async (values) => {
    try {
        const url = `/Auth/ForgetPassword`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const forgetPasswordCallback = async (values) => {
    try {
        const url = `/Auth/ForgetPasswordCallBack`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const resetPassword = async (values) => {
    try {
        const url = `/Auth/ResetPassword`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const forgetUsername = async (values) => {
    try {
        const url = `/Auth/ForgetUserName`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const logoutUser = async (values) => {
    try {
        const url = `/Auth/Logout`
        const request = await RQ
        const response = await request.post(url, values)
        clearStorage()
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getUser = async () => {
    try {
        const url = `/UserManager/getProfile`
        const request = await RQ
        const response = await request.post(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const deleteUser = async (email) => {
    try {
        const url = `/UserManager/DeleteUser?email=${email}`
        const request = await RQ
        const response = await request.delete(url)
        clearStorage()
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const updateUserLanguage = async (languageId) => {
    try {
        const url = `/UserManager/updateuserlanguage`
        const request = await RQ
        const response = await request.put(url, languageId)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const updateUserDetails = async (values) => {
    try {
        const url = `/UserManager/updateUserDetails`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindCourseObjectiveByCourseId = async (id) => {
    try {
        const url = `/CourseObjective/FindCourseObjectiveByCourseId?CourseId=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const courseEnroll = async (UserId, CourseId) => {
    try {
        const url = `/Course/Enrol?UserId=${UserId}&CourseId=${CourseId}`
        const request = await RQ
        const response = await request.post(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const checkIfEnrolled = async (CourseId) => {
    try {
        const url = `/Course/CheckIfIsEnrolled?CourseId=${CourseId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getEnrolledCourses = async (Page, PageSize) => {
    try {
        const url = `/Course/GetEnrolledCourses?Page=${Page}&PageSize=${PageSize}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getFaq = async () => {
    try {
        const url = `/Faq/getQuestions`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getFaqRes = async () => {
    try {
        const url = `/Faq/getResponses`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const postContactForm = async (values) => {
    try {
        const url = `/ContactUs/send`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getUsersProgressOnCourse = async (courseId, id) => {
    try {
        const url = `/UserCourseProgres/get?courseId=${courseId}&userId=${id}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getCourseReview = async (courseId, page, pgSize) => {
    try {
        const url = `/Review/FindByCourseId?courseId=${courseId}&pageNumber=${page}&pagesize=${pgSize}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const setProgressOnCourse = async (values) => {
    try {
        const url = `/UserCourseProgres/create`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const setCourseReview = async (values) => {
    try {
        const url = `/Review/AddCourseReview`
        const request = await RQ
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getResourceById = async (resourceId) => {
    try {
        const url = `/Resource/GetResourceById?Id=${resourceId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getDownloadCourseCertificate = async (courseId, userId) => {
    try {
        const url = `/Course/DownloadCourseCertificate?courseId=${courseId}&userId=${userId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getUserTestResults = async (userId) => {
    try {
        const url = `/Test/FindtestByUserId?userId=${userId}`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
export const getCourseCategories = async () => {
    try {
        const url = `/CourseCategory/findCourseCategories`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getCourseByCategoryId = async (id) => {
    try {
        const url = `/CourseCategory/findCourseByCategoryId?categoryId=${id}&page=1&pageSize=100`
        const request = await RQ
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}