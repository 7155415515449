import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
// import S3 from 'react-aws-s3'
// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
// import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { Button, Form, Input, Select, Modal } from 'antd'
import { useForm, useInstructorData, useFileUpload } from '../../../../hooks'
import {
    CreateNewLesson
} from '../../../../services';
// import Infoicon from '../../../../assets/icons/alert-circle.svg'
import fileIcon from '../../../../assets/icons/file.svg'
import delIcon from '../../../../assets/icons/delIcon.svg'
import DoneLoading from '../../../../assets/icons/Done_loading.svg'
import { openNotification } from '../../../../utils/helpers'
import { RequestLoader } from '../../../../components/Loading';
// STYLES ARE IN /instructorDashboard/CreateEditCourses/styles.scss

export function Uploadlist({ name, onClick }) {
    return (
        <div
            className="uploadList"
            key={name}
        >
            <div className="img__cont">
                <img src={fileIcon} alt="file Icon" />
            </div>
            <p title={name}>{name}</p>
            <div onClick={onClick} className="img__cont del__icon">
                <img src={delIcon} alt="del icon" />
            </div>
        </div>
    )
}

// CONVERTING FILESIZE
export const byteToMb = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
}

export function CreateLesson() {
    const {
        lessonForm,
        setLessonTopic,
        setLessonDoc,
        setLessonTitle,
        setLessonDescription,
        setLessonLanguage,
        setLessonMedia,
        clearLessonField,
        removeDocs
    } = useForm()

    const {
        state: { instructorID, languages },
        getLanguages
    } = useInstructorData()

    const uploadResourseToS3 = useFileUpload()

    // const {
    //     state: { data: user },
    // } = useAuth()

    const { TextArea } = Input;
    const loc = document.location.pathname;
    const [openModal, setOpenModal] = useState(false)
    const [showMediaOutline, setShowMediaOutline] = useState(false)
    const [showDocsOutline, setShowDocsOutline] = useState(false)
    const [isLoading, setIsloading] = useState(false)

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getLanguages(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Necessary for utilizing the Select field option provode
    const { Option } = Select;
    const bool = loc === '/tutor/create-lesson'
    // Validation and checking for Empty input filed
    function validateForm() {
        if (lessonForm.title === '') {
            openNotification({
                title: 'Lesson Title',
                type: 'error',
                message: 'Lesson title is required'
            })
            return false
        } else if (lessonForm.topic === '') {
            openNotification({
                title: 'Lesson Topic',
                type: 'error',
                message: 'Lesson topic can\'t be empty'
            })
            return false
        } else if (lessonForm.language === '') {
            openNotification({
                title: 'Lesson Language',
                type: 'error',
                message: 'In what language is the lesson delivered?'
            })
            return false
        } else if (lessonForm.description === '') {
            openNotification({
                title: 'Lesson Description',
                type: 'error',
                message: 'Lesson description field can\'t be empty'
            })
            return false
        } else if (Object.values(lessonForm.media).length < 1) {
            openNotification({
                title: 'Lesson Media',
                type: 'error',
                message: 'Kindly provide media related to this lesson'
            })
            return false
        }
        return true
    }

    // FORM SUBMIT FUNCTION
    const submitForm = async () => {
        setIsloading(true)
        let videoUrl = ''
        const resourceUrl = []
        try {
            // Upload video to storage
            const media = await uploadResourseToS3(
                lessonForm.media.file.name,
                lessonForm.media.file
            )
            if (media.error) {
                throw new Error('An error occured while uploading media')
            } else {
                videoUrl = media.url
                // Upload document if any is available
                if (lessonForm.document.length >= 1) {
                    for (let i = 0; i < lessonForm.document.length; i++) {
                        // eslint-disable-next-line no-await-in-loop
                        const fileUrl = await uploadResourseToS3(
                            lessonForm.document[i].file.name,
                            lessonForm.document[i].file
                        )
                        if (fileUrl.error) {
                            throw new Error('An error occured while uploading lesson documents')
                        } else {
                            resourceUrl.push(fileUrl.url)
                        }
                    }
                }
            }
            const data = {
                name: lessonForm.title,
                instructorId: instructorID,
                summary: lessonForm.description,
                contentUrl: videoUrl,
                contentName: lessonForm.media.file.name,
                contentType: lessonForm.media.file.type,
                resourcesUrl: resourceUrl,
                totalLength: 0,
                isIntroLesson: false,
            }
            const response = await CreateNewLesson(data)
            if (response.message === 'Success') {
                openNotification({
                    type: 'success',
                    title: 'Lesson Creation',
                    message: response.message,
                })
                setIsloading(false)
                clearLessonField()
            }
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Lesson Creation',
                message: error.message,
            })
            setIsloading(false)
            setOpenModal(false)
        }
    }

    return (
        <div id="CreateLesson" className="form__cont">
            {loc === '/tutor/create-lesson' ? <h2>Create Lesson</h2> : '' }
            <Form>
                <div className="form__item__cont">
                    <label>Lesson Title</label>
                    <Form.Item>
                        <Input
                            id="CourseName"
                            name="course name"
                            placeholder="Name of Lesson..."
                            value={lessonForm.title}
                            onChange={(e) => {
                                const word = e.target.value
                                const capitalFirstLet = word.charAt(0).toUpperCase() + word.slice(1)
                                setLessonTitle(capitalFirstLet)
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="form__item__cont">
                    <label>Lesson Topic</label>
                    <Form.Item>
                        <TextArea
                            id="lessonTopic"
                            className="textbox"
                            // showCount
                            maxLength={300}
                            name="Lesson Topic"
                            placeholder="Topics to be covered in this lesson"
                            value={lessonForm.topic}
                            onChange={(e) => setLessonTopic(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div style={{ marginBottom: '2em' }} className="form__item__cont">
                    <label>Language</label>
                    <div className="lang__cont">
                        <h4>Language</h4>
                        <Select
                            id="setLessonLanguage"
                            className="inner__select"
                            style={{ width: '80%' }}
                            value={lessonForm.language}
                            onChange={(value) => setLessonLanguage(value)}
                        >
                            {
                                languages && languages.map((lang) => (
                                    <Option key={lang.id} value={lang.name} label={lang.name}>{lang.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                <div className="form__item__cont">
                    <label>Lesson Description</label>
                    <Form.Item>
                        <TextArea
                            id="lessonDescription"
                            className="textbox"
                            // showCount
                            maxLength={300}
                            name="Lesson Description"
                            placeholder="What does the lesson involve?"
                            value={lessonForm.description}
                            onChange={(e) => setLessonDescription(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className="form__item__cont">
                    <label>Media</label>
                    <Form.Item>
                        <div style={{ outline: showMediaOutline ? '2px solid red' : '', cursor: 'pointer' }} className="file__upload file_1">
                            <h6>Drop file here</h6>
                            <br />
                            <p>or</p>
                            <br />
                            <p
                                style={{
                                    border: '2px solid #3a86ff',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    borderRadius: '15px',
                                    height: '35px',
                                    cursor: 'pointer'
                                    // outline: '2px solid red'
                                }}
                            >
                                Select file
                                <input
                                    type="file"
                                    accept="video/mp4, video/mkv, video/mov, video/webm"
                                    name="file"
                                    id="file_1"
                                    title=""
                                    encType="multipart/form-data"
                                    style={{
                                        width: '200px',
                                        position: 'relative',
                                        top: '-30px',
                                        opacity: '0',
                                        cursor: 'pointer'
                                    }}
                                    onChange={e => {
                                        const fileSz = byteToMb(e.target.files[0].size)
                                        if (fileSz <= 100) {
                                            setLessonMedia({ 'file': e.target.files[0] })
                                        } else {
                                            setShowMediaOutline(true)
                                            openNotification({
                                                title: 'Media size',
                                                type: 'error',
                                                message: 'Media size too large ( <100mb )'
                                            })
                                            setTimeout(() => { setShowMediaOutline(false) }, 3000)
                                        }
                                    }}
                                />
                            </p>
                        </div>
                    </Form.Item>
                    {Object.values(lessonForm.media).map((file, idx) => {
                        return (
                            <Uploadlist
                                id="setLessonMedia"
                                key={idx}
                                name={file.name}
                                onClick={() => setLessonMedia('')}
                            />
                        )
                    })}
                </div>
                <div className="form__item__cont">
                    <label>Lesson document <sup style={{ color: '#C0C0C0' }}>( Optional )</sup></label>
                    <Form.Item>
                        <div style={{ outline: showDocsOutline ? '2px solid red' : '', cursor: 'pointer' }} className="file__upload">
                            <h6>Drop file here</h6>
                            <br />
                            <p>or</p>
                            <br />
                            <p
                                style={{
                                    border: '2px solid #3a86ff',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    borderRadius: '15px',
                                    height: '35px',
                                    cursor: 'pointer'
                                }}
                            >
                                Select file
                                <input
                                    accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
                                    type="file"
                                    name="file"
                                    id="file_2"
                                    title=""
                                    style={{
                                        width: '200px',
                                        position: 'relative',
                                        top: '-30px',
                                        opacity: '0',
                                        cursor: 'pointer'
                                    }}
                                    onChange={e => {
                                        const fileSz = byteToMb(e.target.files[0].size)
                                        if (fileSz <= 5) {
                                            setLessonDoc({ 'file': e.target.files[0] })
                                        } else {
                                            setShowDocsOutline(true)
                                            openNotification({
                                                title: 'Document size',
                                                type: 'error',
                                                message: 'Document size too large ( <5mb )'
                                            })
                                            setTimeout(() => { setShowDocsOutline(false) }, 3000)
                                        }
                                    }}
                                />
                            </p>
                        </div>
                    </Form.Item>
                    {lessonForm.document && lessonForm.document.map((file, idx) => {
                        return (
                            <Uploadlist
                                key={idx}
                                name={file.file.name}
                                id="removeDocs"
                                onClick={() => removeDocs(file.file.name)}
                            />
                        )
                    })}
                </div>
                <div className="btn__cont">
                    <Button id="ClearLessonField" onClick={clearLessonField} type="primary">Clear all</Button>   {/* an onclick event that reset all field */}
                    <Button
                        /** button to submit the form */
                        id="SubmitForm"
                        onClick={() => {
                            // Wanna check for validation here
                            const cont = validateForm()
                            if (cont) {
                                submitForm()
                                setOpenModal(true)
                            }
                        }}
                        type="primary"
                    >{loc === '/tutor/create-lesson' ? 'Save and exit' : 'Save' }
                    </Button> {/* modal for confirmation msg before submitting */}
                </div>
                <Modal
                    id="CreateLessonModal"
                    centered
                    footer={null}
                    open={openModal}
                    style={{
                        textAlign: 'center',
                    }}
                    onCancel={() => {
                        setOpenModal(false)
                    }}
                >
                    <div>
                        <h4 style={{ color: '#3a86ff' }}>{isLoading ? 'Creating Lesson' : 'Lesson Created'}</h4>
                        <p style={{ marginBlock: '1em' }}>
                            Every Created lessons are accessible in the <b>add lessons field</b> of the <b>create module section</b>
                        </p>
                        {/* {!isLoading &&
                        <p style={{ marginBlock: '1em' }}>
                            <img src={Infoicon} alt="info icon" /> <span>After adding all reasons and resources, click on <b>Save and Close</b>.</span>
                        </p>} */}
                        <div style={{ marginBlock: '2em' }}>
                            {isLoading && <RequestLoader />}
                            {!isLoading &&
                                <img src={DoneLoading} alt="done_loading" />}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                            // cancel button
                                id="continueButton"
                                key={Math.random() * 100}
                                size="middle"
                                type="primary"
                                disabled={isLoading && true}
                                style={{
                                    borderRadius: '10px',
                                    paddingInline: '2em',
                                    // marginRight: '200px',
                                    height: '40px'
                                }}
                                onClick={() => {
                                    setOpenModal(false)
                                    location.reload()
                                }}
                            >{isLoading ? 'Cancel' : 'Continue creating lesson'}
                            </Button>
                            <Link to={bool ? '/tutor/create-module' : '#module_section'}>
                                <Button
                                    /** button to redirect to next page */
                                    id="GoToModuleSection"
                                    key={Math.random() * 100}
                                    size="middle"
                                    disabled={isLoading && true}
                                    type="primary"
                                    style={{
                                        borderRadius: '10px',
                                        paddingInline: '2em',
                                        height: '40px'
                                    }}
                                    onClick={() => {
                                        if (!bool) {
                                            const module = document.getElementById('module_section')
                                            module.scrollIntoView({ behavior: 'smooth' })
                                        }
                                        setOpenModal(false)
                                    }}
                                >{isLoading ? 'Continue creating lesson' : 'Go to module section'}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Modal>
            </Form>
        </div>
    )
}
