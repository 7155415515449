import React, { useState } from 'react'
import { Modal } from 'antd'
import { RequestLoader } from '../../../components/Loading'
import { createConnectUniqueLink } from '../../../services/instructor.service'
import StripeLogo from '../../../assets/icons/Strpe logo.png'
// Styles
import './instructorPayout.scss'

export const InstructorPayout = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [uniqueUrl, setUniqueUrl] = useState('')
    const redirectToStripeConnect = async () => {
        setIsLoading(true)
        // Redirect to Stripe Connect page
        const resp = await createConnectUniqueLink()
        if (resp.status) {
            const response = JSON.parse(resp.data.stripeResponse.content)
            setUniqueUrl(response.url)
            setIsLoading(false)
        }
    }
    return (
        <main id="payoutpage">
            <h2 data-testid="page-title">Payouts</h2>
            <h4 data-testid="subtitle">Select a payout method</h4>
            <section className="paymentOptions">
                <section className="stripeConnect">
                    <div className="img-cont">
                        <img
                            data-testid="stripelogo"
                            src={StripeLogo}
                            alt="StripeLogo"
                        />
                    </div>
                    <div className="text_cont">
                        <p>
                            Zustechlearning will be paying out instructor
                            earnings to the stripe connect account on a monthly
                            basis.
                        </p>
                        <p>
                            Stripe allows you to withdraw to your local bank
                            account in your local currency.
                        </p>
                        <p>
                            You will have to create an account with stripe and
                            be verified
                        </p>
                    </div>
                    <div className="ctas">
                        <a
                            href="https://stripe.com/global"
                            target="_blank"
                            data-testid="link"
                            rel="noreferrer"
                        >
                            Countries that support stripes
                        </a>
                        <button
                            data-testid="cta"
                            onClick={() => {
                                setOpenModal(true)
                                redirectToStripeConnect()
                            }}
                        >
                            Create Connect Link
                        </button>
                    </div>
                </section>
            </section>
            <Modal
                className="CreateCustomerLink"
                centered
                footer={null}
                open={openModal}
                style={{
                    textAlign: 'center',
                }}
                onCancel={() => {
                    setOpenModal(false)
                }}
            >
                <div>
                    <h4 style={{ color: '#3a86ff' }}>
                        {isLoading
                            ? 'Creating customer unique link'
                            : 'Link Created'}
                    </h4>
                    <div style={{ marginBlock: '2em' }}>
                        {isLoading && <RequestLoader />}
                        {!isLoading && uniqueUrl !== '' && (
                            <>
                                <p>
                                    Click the button below to connect your
                                    stripe account with zustech
                                </p>
                                <button
                                    onClick={() => {
                                        setOpenModal(false)
                                    }}
                                >
                                    <a
                                        href={uniqueUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Go to Stripe Connect
                                    </a>
                                </button>
                            </>
                        )}
                        {!isLoading && uniqueUrl === '' && (
                            <>
                                <p>An error occured while generating link</p>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </main>
    )
}