import {
    SET_USER_LOCATION,
} from './types'
import { StripeSupported } from '../../../pages/landing/pricing/Payment/currencySymbols'

export const setUserLocation = (input) => {
    const setSymbol = () => {
        const allowedCountries = []
        for (let i = 0; i < StripeSupported.length; i++) {
            allowedCountries.push(StripeSupported[i].countryCode)
        }
        if (input !== null) {
            if (allowedCountries.includes(input.country)) {
                return '£'
            } else {
                return '₦'
            }
        } else {
            return '₦'
        }
    }
    const symbol = setSymbol()

    return {
        type: SET_USER_LOCATION,
        payload: { location: input, symbol }
    }
}
