const data = [
    {
        id: 1,
        username: 'Patrick Kwofie - Business Analyst Students',
        testimonial:
        'Lots of features for building a course, supports multiple languages, provides seamless transition between different types and formats of materials and has a good quiz maker. Fantastic customer service and support',
        image: './images/Grou',
    },

    {
        id: 2,
        username: 'Onome Sheila - Business Analyst Students',
        testimonial:
            "One of the best things about this LMS is the personalized learning experience it offers. With adaptive learning paths and self-paced modules, I can tailor my studies to suit my individual preferences and learning style. It's empowering to have control over my education.",
    },
    {
        id: 3,
        username: 'Emmanuel - Business Analyst Instructor',
        testimonial:
            'As an instructor, I greatly appreciate the flexibility and customization options offered by this LMS. From structuring course content to designing assessments, I have full control over every aspect of the learning process. Plus, the analytics dashboard provides valuable insights into student progress, helping me tailor my instruction to meet their needs.',
    },
    {
        id: 4,
        username: 'Mike Muziringa - Business Analyst Students',
        testimonial:
            "This LMS has truly made my learning experience more convenient and engaging. With easy access to course materials, interactive quizzes, and discussion forums, I feel more connected to my studies than ever before. It's like having a virtual classroom at my fingertips!",
    },

    {
        id: 5,
        username: 'Onome Sheila - Business Analyst Students',
        testimonial:
            "The support and training resources provided by the developers of this LMS are top-notch. From onboarding assistance to ongoing professional development opportunities, they're committed to helping instructors make the most of the platform. It's refreshing to see such dedication to customer success.",
    },

    {
        id: 6,
        username: 'Patrick Kwofie - Business Analyst Students',
        testimonial:
            "I've been using this LMS for several semesters now, and I continue to be impressed by its reliability and performance. Even during peak usage times, the platform remains stable, ensuring uninterrupted access for both myself and my students. It's one less thing to worry about in the midst of a busy semester.",
    },
    {
        id: 7,
        username: 'Mike Muziringa - Business Analyst Students',
        testimonial:
            "As a visual learner, I appreciate the multimedia capabilities of this LMS. From interactive videos to visual diagrams, the course content comes alive in a way that resonates with me. It's made studying more enjoyable and effective.",
    },
    {
        id: 8,
        username: 'Emmanuel - Business Analyst Instructor',
        testimonial:
            "This LMS has made my job so much easier in terms of course management and administration. The centralized platform for organizing materials, grading assignments, and communicating with students saves me valuable time and allows me to focus more on teaching. It's truly a teacher's best friend.",
    },
];

export default data;