import { useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import {
    ActiveUserChart,
    AdminFilter,
    AdminInstructorCard,
    AdminCourseCard,
    Coursebarchart,
    CourseRevenueCard,
    RevenueInfo,
} from '../Components/uiFocused'
import {
    revenueData,
    courseRevenueInfo,
    mimickedCourseInfo,
    mimickTopInstructor,
} from './data'
import MLogo from '../../../assets/icons/mLogo.png'
import SLogo from '../../../assets/icons/sLogo.svg'
import OLogo from '../../../assets/icons/oLogo.png'
// import { Bar } from 'react-chartjs-2'
// import Polygon from '../assets/polygon.png'
// import Chat from '../assets/chat.png'
// import Profile from '../assets/profile.png'
// import Frame from '../assets/Frame.png'
import './styles.scss'

export const AdminDB = () => {
    const [filterValue, setFilterValue] = useState('Last 30 days')
    return (
        <main id="admin-dashboard">
            <section className="page_filter" data-testid="page-fiter">
                <AdminFilter value={filterValue} setValue={setFilterValue} />
            </section>
            <section className="revenue-summary" data-testid="revenue-summary">
                {revenueData.map((detail) => (
                    <RevenueInfo
                        key={detail.id}
                        info={detail}
                        filter={filterValue}
                    />
                ))}
            </section>
            <section className="course-revenue" data-testid="course-revenue">
                {courseRevenueInfo.map((detail) => (
                    <CourseRevenueCard
                        key={detail.id}
                        info={detail}
                        filter={filterValue}
                    />
                ))}
            </section>
            <section className="top-chart">
                <div className="top-course">
                    <div className="heading">
                        <h4>Top Courses</h4>
                        <p className="filter">{filterValue}</p>
                        <div className="controlData">
                            <p>Course status</p>
                            <DownOutlined />
                        </div>
                    </div>
                    <div className="maindata">
                        {mimickedCourseInfo.map((courseInfo) => (
                            <AdminCourseCard
                                info={courseInfo}
                                key={courseInfo.id}
                            />
                        ))}
                    </div>
                    <div className="bottom">
                        <div className="upper">
                            <button>View all</button>
                        </div>
                        <div className="legend">
                            <div className="item-cont">
                                <div className="img-cont">
                                    <img src={MLogo} alt="" />
                                </div>
                                <p>Multi-course subscription</p>
                            </div>
                            <div className="item-cont">
                                <div className="img-cont">
                                    <img src={SLogo} alt="" />
                                </div>
                                <p>Single-course subscription</p>
                            </div>
                            <div className="item-cont">
                                <div className="img-cont">
                                    <img src={OLogo} alt="" />
                                </div>
                                <p>One-time purchase</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-instructor">
                    <div className="heading">
                        <h4>Top Instructors</h4>
                        <p className="filter">{filterValue}</p>
                        <div className="controlData">
                            <p className="cta">View all</p>
                        </div>
                    </div>
                    <div className="maindata">
                        {mimickTopInstructor.map((courseInfo) => (
                            <AdminInstructorCard
                                info={courseInfo}
                                key={courseInfo.id}
                            />
                        ))}
                    </div>
                </div>
            </section>
            <section className="top-chart">
                <div id="active-user">
                    <div className="main-row">
                        <div className="top">
                            <h2>Active Users</h2>
                            <p className="filter">{filterValue}</p>
                        </div>
                        <div className="lower">
                            <p>Students avg active usage of the lms</p>
                        </div>
                    </div>
                    <div className="card-info">
                        <div className="monthly-info info">
                            <h4>Monthly</h4>
                            <p className="value">
                                19.6 <span>hrs</span>
                            </p>
                        </div>
                        <div className="weekly-info info">
                            <h4>Weekly</h4>
                            <p className="value">
                                4.9 <span>hrs</span>
                            </p>
                        </div>
                        <div className="daily-info info">
                            <h4>Daily</h4>
                            <p className="value">
                                42 <span>mins</span>
                            </p>
                        </div>
                    </div>
                    <div className="maindata">
                        <ActiveUserChart />
                    </div>
                </div>
                <div id="course-completed">
                    <div className="main-row">
                        <div className="top">
                            <h2>Course completion rate</h2>
                            <p className="filter">{filterValue}</p>
                        </div>
                        <div className="lower">
                            <p>Students percentage course completion rate</p>
                        </div>
                    </div>
                    <div className="card-info diff-card">
                        <div className="rate-info info">
                            <p className="filter">{filterValue}</p>
                            <h4>38%</h4>
                            <p className="value">completion rate</p>
                        </div>
                    </div>
                    <div className="maindata">
                        <Coursebarchart />
                    </div>
                </div>
            </section>
        </main>
    )
}
