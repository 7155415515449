import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top';
// import { Root } from './store'
import { AppRouter } from './routes'
import { fetchUserLocation } from './utils'
import { useLocation } from './hooks';
import { StripeSupported } from './pages/landing/pricing/Payment/currencySymbols'

function App() {
    const { addUserLocation } = useLocation()

    useEffect(() => {
        const updateLocationData = (location) => {
            const allowedCountries = []
            for (let i = 0; i < StripeSupported.length; i++) {
                allowedCountries.push(StripeSupported[i].countryCode)
            }
            localStorage.setItem(
                'zustech:user-location',
                JSON.stringify(location)
            )
            if (allowedCountries.includes(location.country_code)) {
                localStorage.setItem('currentCountry', 'GB')
            } else {
                localStorage.setItem('currentCountry', 'NG')
            }
            addUserLocation(location)
        }
        const setLocationState = async () => {
            const storedLocation = JSON.parse(
                localStorage.getItem('zustech:user-location')
            )
            const location = await fetchUserLocation()
            if (storedLocation) {
                if (storedLocation.city !== location.city) {
                    updateLocationData(location)
                } else {
                    addUserLocation(storedLocation)
                }
            } else {
                updateLocationData(location)
            }
        }

        setLocationState()
        //     function ctrlShiftKey(e, keyCode) {
        //         return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
        //     }

        //     if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
        //         // production build code
        //         // Disable right-click
        //         document.addEventListener('contextmenu', (e) => e.preventDefault());

        //         document.onkeydown = (e) => {
        //         // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        //             if (e.keyCode === 123 || ctrlShiftKey(e, 'I') || ctrlShiftKey(e, 'J') || ctrlShiftKey(e, 'C') || (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        //             ) return false;
        //         };
        //     }
        // eslint-disable-next-line
    }, [])

    return (
        <BrowserRouter>
            <ScrollToTop>
                <AppRouter />
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default App