import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'
import axios from 'axios';
import { useAuth, useInstructorData } from '../../../hooks'
import './instructorDB.scss'
import { Coursecard } from '../MyCourses/courseCard/index';
import { RequestLoader } from '../../../components/Loading';
import  { Filter } from '../../../components/InstructorCourseFilter/courseFilter'
import guide1 from './assets/Course creation guide.svg'
import guide2 from './assets/Course creation 2.svg'
import guide3 from './assets/Course creation 3.svg'
import guide4 from './assets/Course creation 4.png'
import guide5 from './assets/Course creation 5.svg'
import guide6 from './assets/Course creation 6.svg'
import guide7 from './assets/Course creation 7.png'
import guide8 from './assets/Course creation 8.svg'
import RelatedGuide1 from './assets/Related guide 1.svg'
import RelatedGuide2 from './assets/Related guide 2.svg'
import RelatedGuide3 from './assets/Related guide 3.svg'
import RelatedGuide4 from './assets/Related guide 4.svg'

export const InstructorDB = () => {
    const {
        state: { data: user },
    } = useAuth()

    const {
        state: { isLoading, courses, isFiltered, filteredCourse, instructorID },
        getAllCourses,
        getAllModules,
        getAllLessons,
        getLanguages,
        getCourseCategory,
    } = useInstructorData()

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getLanguages(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getCourseCategory(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getAllCourses(instructorID, source.token)
            await getAllModules(instructorID, source.token)
            await getAllLessons(instructorID, source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    return (
        <div id="instructor-dashboard">
            <div id="header">
                <h2 className="title">Hello {user?.firstName}</h2>
            </div>
            <div className="hero">
                <div className="img__container">
                    <div className="hero__overlay">&nbsp;</div>
                    {/* <h3>Course Marketplace</h3> */}
                </div>
            </div>
            <section className="course__cont">
                <div className="section__heading">
                    <h3>My Courses</h3>
                    <NavLink
                        to="/tutor/create-products"
                        id="homeToCreateNewCourse"
                    >
                        <Button
                            style={{
                                borderRadius: '10px',
                                paddingInline: '15px',
                            }}
                            type="primary"
                            ghost
                        >
                            Create New Course
                        </Button>
                    </NavLink>
                </div>
                <div className="filter__section">
                    <Filter />
                </div>
                <div className="card__cont">
                    {/* Error message for empty filter result */}
                    {isFiltered && filteredCourse.length < 1 && (
                        <div className="empty_data">
                            <h4>No course for selected filter</h4>
                        </div>
                    )}
                    {/* Error message for no course from DB */}
                    {!isLoading && courses.length < 1 && (
                        <div className="empty_data">
                            <h4>
                                You have not created your first course yet.
                                Start creating your first course now!
                            </h4>
                        </div>
                    )}
                    {/* Rendering fectched course after loading and when filter is not in use */}
                    {!isLoading &&
                        !isFiltered &&
                        courses &&
                        courses.map((listCont, idx) => {
                            return <Coursecard key={idx} type={listCont} />
                        })}
                    {/* Rendering filtered course */}
                    {isFiltered &&
                        filteredCourse.map((listCont, idx) => {
                            return <Coursecard key={idx} type={listCont} />
                        })}
                    {/* Request Loader */}
                    {isLoading && (
                        <div className="loader_cont">
                            <RequestLoader />
                        </div>
                    )}
                </div>
            </section>
            <section className="course_regulation">
                <h3>Course creation guidelines</h3>
                <div className="content__to__come" id="toInstructorGuidePage">
                    <NavLink to="/tutor/guide/UnderstandingOurCourseStructure">
                        <img src={guide1} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/PlanningYourCourse">
                        <img src={guide2} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/RecordingLessonsAndMediaQualityStandard">
                        <img src={guide3} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/UploadingYourLessons">
                        <img src={guide4} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/AddingYourLessonsIntoModules">
                        <img src={guide5} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/UploadingCourses">
                        <img src={guide6} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/BestPracticesForCreatingQuiz">
                        <img src={guide7} alt="" />
                    </NavLink>
                    <NavLink to="/tutor/guide/UpdateOfCourseContent">
                        <img src={guide8} alt="" />
                    </NavLink>
                </div>
            </section>
            <section className="course_regulation">
                <h3>Related guidelines</h3>
                <div className="content__to__come">
                    <img src={RelatedGuide1} alt="" />
                    <img src={RelatedGuide2} alt="" />
                    <img src={RelatedGuide3} alt="" />
                    <img src={RelatedGuide4} alt="" />
                </div>
            </section>
        </div>
    )
}