import React, { useEffect, useState } from 'react'
import { Rate } from 'antd'

export const AdminInstructorCard = ({ info }) => {
    const [initials, setInitials] = useState('')

    useEffect(() => {
        const names = info.name.split(' ')
        names.forEach((name) => {
            const firstLetter = name[0]
            setInitials(prevValue => prevValue + firstLetter)
        })
    }, [info.name])

    return (
        <div
            data-testid={`${info.id}-instructor-card`}
            id="top-instructor-card"
        >
            <div data-testid="initials" className="initials-cont">
                <p>{initials}</p>
            </div>
            <div className="info-cont">
                <div className="top-layer">
                    <p data-testid="instructor-name">{info.name}</p>
                    <div>
                        <Rate
                            disabled
                            allowHalf
                            value={info.avgStar}
                            style={{
                                fontSize: '0.9em',
                                marginInline: '3px',
                            }}
                        />
                    </div>
                </div>
                <div className="lower-layer">
                    <p data-testid="instructor-email"> {info.email} </p>
                    <p data-testid="dynamic">
                        <span>{info.review}</span>
                        <span className="dynamic"> reviews</span>
                    </p>
                </div>
            </div>
        </div>
    )
}