import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { MdArrowForwardIos } from 'react-icons/md'
import { Accordion } from 'react-accessible-accordion'
import { AccordionComponent } from '../components/Accordion'
import {
    troubleshootingFAQs,
    accountsProfileFAQs,
    takeOurCoursesFAQs,
    subscriptionAndBillingFAQs,
    gettingStartedFAQs,
} from './component/constant'
import 'react-accessible-accordion/dist/fancy-example.css'
import './studentsupport.scss'
import group1 from './assets/group1.svg'
import group2 from './assets/group2.svg'

export const StudentSupport = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const resource = searchParams.get('resource')

    const [currentFAQ, setCurrentFAQ] = useState({
        name: 'Getting started',
        title: 'Getting Started',
    })
    const [value, setValue] = useState(gettingStartedFAQs)

    useEffect(() => {
        switch (resource) {
            case 'start':
                setValue(gettingStartedFAQs)
                setCurrentFAQ({
                    name: 'Getting started',
                    title: 'Getting Started',
                })
                break
            case 'accounts':
                setValue(accountsProfileFAQs)
                setCurrentFAQ({
                    name: 'Accounts',
                    title: 'Account Settings',
                })
                break
            case 'subscription':
                setValue(subscriptionAndBillingFAQs)
                setCurrentFAQ({
                    name: 'Subscription',
                    title: 'Subcription & Billing',
                })
                break
            case 'course':
                setValue(takeOurCoursesFAQs)
                setCurrentFAQ({
                    name: 'Course',
                    title: 'Take Course',
                })
                break
            case 'troubleshoot':
                setValue(troubleshootingFAQs)
                setCurrentFAQ({
                    name: 'Troubleshoot',
                    title: 'Troubleshooting',
                })
                break
            default:
                setValue(gettingStartedFAQs)
                setCurrentFAQ({
                    name: 'Getting started',
                    title: 'Getting Started',
                })
                break
        }
    }, [resource])

    useEffect(() => {
        switch (currentFAQ.name) {
            case 'Getting started':
                setValue(gettingStartedFAQs)
                break
            case 'Accounts':
                setValue(accountsProfileFAQs)
                break
            case 'Subscription':
                setValue(subscriptionAndBillingFAQs)
                break
            case 'Course':
                setValue(takeOurCoursesFAQs)
                break
            case 'Troubleshoot':
                setValue(troubleshootingFAQs)
                break
            default:
                setValue(gettingStartedFAQs)
                break
        }
    }, [currentFAQ.name])

    return (
        <div id="student-support">
            <div id="student-support">
                <div className="top-background">
                    <div className="imge-cont group1-img">
                        <img src={group1} alt="" />
                    </div>
                    <div className="imge-cont group2-img">
                        <img src={group2} alt="" />
                    </div>
                    <input
                        placeholder="Search articles"
                        name="search-article"
                        id="search-article"
                        className="search-article"
                    />
                </div>
                <div className="top-link">
                    <span>Students support</span>
                    <MdArrowForwardIos />
                    <span>{currentFAQ.name}</span>
                    <MdArrowForwardIos />
                </div>
                <nav className="nav-btns">
                    <button
                        onClick={() => {
                            setCurrentFAQ({
                                name: 'Getting started',
                                title: 'Getting Started',
                            })
                        }}
                        className="btn"
                    >
                        Getting started
                    </button>
                    <button
                        onClick={() => {
                            setCurrentFAQ({
                                name: 'Accounts',
                                title: 'Account Settings',
                            })
                        }}
                        className="btn"
                    >
                        Account/Profile
                    </button>
                    <button
                        onClick={() => {
                            setCurrentFAQ({
                                name: 'Subscription',
                                title: 'Subcription & Billing',
                            })
                        }}
                        className="btn"
                    >
                        Subscription/billing
                    </button>
                    <button
                        onClick={() => {
                            setCurrentFAQ({
                                name: 'Course',
                                title: 'Take Course',
                            })
                        }}
                        className="btn"
                    >
                        Take course
                    </button>
                    <button
                        onClick={() => {
                            setCurrentFAQ({
                                name: 'Troubleshoot',
                                title: 'Troubleshooting',
                            })
                        }}
                        className="btn"
                    >
                        Troubleshooting
                    </button>
                </nav>
                <section className="content-settings">
                    <h2>{currentFAQ.title}</h2>
                    <p>
                        Welcome to Zustech! These FAQs will you navigate and use
                        the system effectively.
                    </p>
                    <div className="questions-container">
                        <Accordion>
                            {value.map((faq) => {
                                return (
                                    <AccordionComponent
                                        key={faq.id}
                                        question={faq.question}
                                        answers={faq.response}
                                    />
                                )
                            })}
                        </Accordion>
                    </div>
                </section>
            </div>
        </div>
    )
}