import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth  } from '../../../../hooks'

export const MultiSubSuccess = ({ data }) => {
    const {
        state: { data: userInfo },
    } = useAuth()

    const history = useHistory()

    return (
        <main id="subscription_cont">
            {data.paymentSuccess && (
                <div className="content_cont">
                    <section className="left_Hside">
                        {/* <h3>{day}/{month}/{year}</h3> */}
                        <h3>{data.startDate}</h3>
                        <div className="subs_info">
                            <h3>
                                <span>Multi-Course Subscription</span>
                            </h3>
                            <div>
                                {/* <p>This is the {data.planName}:</p> */}
                                <p>
                                    We're excited to let you know your
                                    subscription was successful.
                                </p>
                                <p>
                                    We are thrilled to help on this learning
                                    journey and we're here every step of the
                                    way.
                                </p>
                            </div>
                            {!data.paymentSuccess && <div>{data.message}</div>}
                            {data.paymentSuccess && (
                                <div className="btn_cont">
                                    <button
                                        id="redirectBtn"
                                        onClick={() => {
                                            // remove key from storage and redirect to course main page
                                            sessionStorage.removeItem(
                                                'Subscribed_Data'
                                            )
                                            // localStorage.removeItem('Paystack paymentRef')
                                            history.push('/me/courses')
                                        }}
                                    >
                                        Course Marketplace
                                    </button>
                                </div>
                            )}
                            {/* <p>Click the button below to get started</p> */}
                        </div>
                        {/* <h3 className="renewal_date">
                            Renews on: <br /> <span>{data.renewalDate}</span>
                        </h3>
                        <h3>
                            {data.symbol} {data.planAmt}
                        </h3> */}
                    </section>
                    <section className="right_Hside">
                        <h2>Thank you for subscribing!</h2>
                        <div className="topper">
                            <p className="userInfo">
                                Hello{' '}
                                <b>
                                    {userInfo.firstName} {userInfo.lastName}
                                </b>
                                <br />
                                You now have access to a wide range of our courses.
                            </p>
                            <p>
                                We hope you find your time here with us to be
                                fun and safe. If there’s anything we can do to
                                make the experience better. Please let us know!
                            </p>
                        </div>
                        <div className="not_topper">
                            <p>
                                Your subscription will automatically renew on
                                the last renewal date. If this was a mistake you
                                have 14 days to cancel the subscription
                            </p>
                            <p>
                                Please visit our support page if you are
                                experiencing any issues or have any questions!
                            </p>
                        </div>
                        <h4>
                            Thanks again, <br /> Zustech Team!
                        </h4>
                    </section>
                </div>
            )}
        </main>
    )
}