import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Form, Input, Select, Collapse, Modal } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Skeleton from 'react-loading-skeleton'
import { openNotification } from '../../../../utils/helpers'
import Beginner from '../../../../assets/icons/difficulty_beginner.svg'
import Intermediate from '../../../../assets/icons/difficulty_intermediate.svg'
import Advance from '../../../../assets/icons/difficulty_advanced.svg'
import CancelIcon from '../../../../assets/icons/cancel_item.svg'
import DropIcon from '../../../../assets/icons/drop_icon.svg'
import DoneLoading from '../../../../assets/icons/Done_loading.svg'
import { Uploadlist, byteToMb } from '../CreateLesson/createLesson'
import delIcon from '../../../../assets/icons/delIcon.svg'
import { useForm, useInstructorData, useFileUpload, useLocation } from '../../../../hooks'
import { CourseIntroLessons } from './CourseIntroLessons'
import { getCourseCategory,
    findAllLanguages,
    getInstructorTierPrice,
    CreateNewLesson,
    FindLessonsById,
    findModuleByInstructorId
} from '../../../../services'
import { RequestLoader } from '../../../../components/Loading';
// STYLES ARE IN /instructorDashboard/CreateEditCourses/styles.scss
// findModuleByInstructorId,

const PaneHeader = ({ itm }) => {
    return (
        <div className="pane_header">
            <p title={itm.label}>{itm.label}</p>
            <div>
                <span title="Lessons Created" className="first_span">{itm.total} Lesson Created</span>
                <span className="info">(Max of 4 Lessons)</span>
            </div>
        </div>
    )
}

export function CreateCourse({ title }) {
    const {
        state: { instructorID },
    } = useInstructorData()
    const { Panel } = Collapse;
    const {
        state: { location, currencySymbol },
    } = useLocation()

    const {
        courseForm,
        setCourseCategory,
        setCourseDiffLvl,
        setCourseLang,
        setCourseModules,
        setCourseObjectives,
        setCourseTitle,
        setCourseType,
        setCoursePrice,
        setCoursePrerequisite,
        setCoverImg,
        setIntroLessons,
        setCourseDescription,
        clearCourseField,
        removeCoverImg,
        removeFromObj,
        removeFromPrequi
    } = useForm()
    const loc = document.location.pathname;
    // DATA STATES
    const [chckBx1, setChckBx1] = useState(false)
    const [chckBx2, setChckBx2] = useState(false)
    const [showMediaOutline, setShowMediaOutline] = useState(false)
    const [fetchingModules, setFetchingModules] = useState(true)
    const [currentText, setCurrentText] = useState('')
    const [summaryText, setSummaryText] = useState('')
    const [isError, setIsError] = useState(false)
    const [crsPrice, setCrsPrice] = useState('')
    const levels = ['All', 'Beginner', 'Intermediate', 'Advance']
    const [availablelanguages, setAvailableLanguages] = useState([])
    const [availableCategory, setAvailableCategory] = useState([])
    const [availableModules, setAvailableModules] = useState([])
    const [option, setOption] = useState([])
    const [ready, setReady] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    // const [symbol, setSymbol] = useState('')
    const [startPrice, setStartprice] = useState('')
    const [endPrice, setEndprice] = useState('')
    // Standard Price visibility state
    const [showPC, setShowPC] = useState(false)
    const { TextArea } = Input;
    const { Option } = Select;

    const introItem = [
        {
            key: 1,
            label: 'Introductory Lesson',
            total: Object.keys(courseForm.introLessons).length,
            children: <CourseIntroLessons />
        }
    ]

    // VALIDATION AND VALUE CHECKING
    function validateForm() {
        if (courseForm.title === '') {
            openNotification({
                title: 'Course Title',
                type: 'error',
                message: 'Course title field can\'t be blank'
            })
            return false
        } else if (courseForm.description === '') {
            openNotification({
                title: 'Course Description',
                type: 'error',
                message: 'Course description field can\'t be empty'
            })
            return false
        } else if (courseForm.difficultyLevel === 'Select difficulty level...') {
            openNotification({
                title: 'Course Difficulty',
                type: 'error',
                message: 'Difficulty level must be selected'
            })
            return false
        } else if (courseForm.type === '') {
            openNotification({
                title: 'Course Type',
                type: 'error',
                message: 'Course type is neccessary'
            })
            return false
        } else if (showPC && courseForm.coursePrice === '') {
            openNotification({
                title: 'Course Price',
                type: 'error',
                message: 'Kindly Input a price for the course'
            })
            return false
        } else if (courseForm.category === 'Select category...') {
            openNotification({
                title: 'Course Category',
                type: 'error',
                message: 'Select the category for this course'
            })
            return false
        } else if (Object.keys(courseForm.coverImage).length > 1) {
            openNotification({
                title: 'Course Image',
                type: 'error',
                message: 'Kindle provide a cover image'
            })
            return false
        } else if (Object.keys(courseForm.introLessons).length < 1) {
            openNotification({
                title: 'Introductory Lesson(s)',
                type: 'error',
                message: 'Select Introductory Lesson(s)'
            })
            return false
        } else if (courseForm.language === 'Select language...') {
            openNotification({
                title: 'Course Language',
                type: 'error',
                message: 'Select the Language the course is been delivered'
            })
            return false
        } else if (chckBx1 === false || chckBx2 === false) {
            openNotification({
                title: 'Terms and Conditions',
                type: 'error',
                message: 'Do well to read about out Terms and Conditions'
            })
            return false
        }
        return true
    }

    function getCourseLanguageById(languages, languageId) {
        const foundLanguage = languages.find(language => language.id === languageId);
        return foundLanguage ? foundLanguage.name : 'Select language...';
    }

    function mapping(value, arr) {
        return (arr.findIndex(element => element.toLowerCase() === value.toLowerCase()) + 1)
    }

    const dataTopic = []
    for (let i = 0; i < Object.keys(courseForm.modules).length; i++) {
        for (let z = 0; z < availableModules.length; z++) {
            if (courseForm.modules[i + 1] === availableModules[z].name) {
                dataTopic.push(availableModules[z])
                break
            }
        }
    }

    const handleDragEnd = (result) => {
        const { destination, source } = result;
        // Below check for destination, making it impossible to drop item outside a droppable container
        if (!destination) {
            return
        }
        // Below makes it impossible to reorder the left column
        if (destination.droppableId === 'left_box' && source.droppableId === 'left_box') {
            return
        }
        // creating a new DS for the right column
        if (destination.droppableId === 'right_box') {
            const rightColumnItem = courseForm.modules
            const oldOrder = option
            const start = source.index + 1
            const end = destination.index + 1
            if (source.droppableId === 'right_box') {
                if (start === end) {
                    return
                }
                const moving = rightColumnItem[start]
                delete rightColumnItem[start]
                if (start < end) {
                    for (let i = start; i < end; i++) {
                        rightColumnItem[i] = rightColumnItem[i + 1]
                    }
                }
                if (start > end) {
                    for (let i = start; i > end; i--) {
                        rightColumnItem[i] = rightColumnItem[i - 1]
                    }
                }
                rightColumnItem[end] = moving
                setCourseModules(rightColumnItem)
            } else {
                const obj = option[source.index]
                let len = Object.keys(rightColumnItem).length
                rightColumnItem[++len] = obj
                oldOrder.splice(source.index, 1)
                setOption(oldOrder)
                setCourseModules(rightColumnItem)
            }
            // Store the state in localstorage as well, the newOption i mean
        } else {
            const rightColumnItem = courseForm.modules
            const start = source.index + 1
            const len = Object.keys(rightColumnItem).length
            const moving = rightColumnItem[start]
            const oldOrder = option
            // remove item from the rightcolumn
            delete rightColumnItem[start]
            if (start !== len) {
                // adjust the keys of the rightcolumn
                for (let i = start; i < len; i++) {
                    rightColumnItem[i] = rightColumnItem[i + 1]
                }
                // This removes the duplicate of the last Item
                delete rightColumnItem[len]
            }
            setCourseModules(rightColumnItem)
            localStorage.setItem('Ordered Modules', JSON.stringify(rightColumnItem))
            // add the removed item to the left column
            oldOrder.splice(destination.index, 0, moving)
            setOption(oldOrder)
        }
    }

    function handleDel(e) {
        const itemId = parseInt(e.target.parentElement.parentElement.dataset.rbdDragHandleDraggableId, 10)
        const rightcolumn = courseForm.modules
        const oldOption = option
        const len = Object.keys(rightcolumn).length
        let obj
        for (let i = 1; i <= len; i++) {
            if (rightcolumn[i]?.key === itemId) {
                obj = rightcolumn[i]
                delete rightcolumn[i]
                oldOption.push(obj)
                for (let j = i; j <= len; j++) {
                    rightcolumn[j] = rightcolumn[j + 1]
                }
            }
        }
        delete rightcolumn[len]
        setOption(oldOption)
        setCourseModules(rightcolumn)
    }

    async function setData() {
        let langId
        for (let i = 0; i < availablelanguages.length; i++) {
            if (availablelanguages[i].name === courseForm.language) {
                langId = availablelanguages[i].id
            }
        }
        // get the image and introvideo
        // upload to s3 bucket
        // get back the url
        // create a lesson with the video url
        // send the url alongside other data or course creation
        try {
            const coverImgUrl = await uploadResourseToS3(
                courseForm.coverImage.file.name,
                courseForm.coverImage.file
            )
            let lessonsUrl = []

            if (coverImgUrl.error) {
                throw new Error('Cover-image upload failed')
            } else {
                // i don't want to create a new lesson when user is editing course
                // so i need to check if there is an a created intro lesson before creating a new one
                const store = JSON.parse(localStorage.getItem('Preview'))
                if (store !== null) {
                    lessonsUrl = store.introLessons
                } else {
                    const introLessonArray = Object.values(courseForm.introLessons)
                    for (let i = 0; i < introLessonArray.length; i++) {
                    // eslint-disable-next-line no-await-in-loop
                        const url = await uploadResourseToS3(
                            introLessonArray[i].file.name,
                            introLessonArray[i].file
                        )
                        if (url.error) {
                            throw new Error('Introductory lesson media upload failed')
                        } else {
                            const lessonData = {
                                name: introLessonArray[i].title,
                                instructorId: instructorID,
                                summary: introLessonArray[i].title,
                                contentUrl: url.url,
                                contentName: introLessonArray[i].file.name,
                                contentType: introLessonArray[i].file.type,
                                resourcesUrl: [],
                                totalLength: 0,
                                isIntroLesson: true,
                            }
                            // eslint-disable-next-line no-await-in-loop
                            const response = await CreateNewLesson(lessonData)
                            if (response.message === 'Success') {
                                lessonsUrl.push({
                                    id: response.data.id,
                                    index: i,
                                })
                            } else {
                                throw new Error("Couldn't create introductory lessons")
                            }
                        }
                    }
                }
            }

            const courseData = {
                name: courseForm.title,
                instructorId: instructorID,
                resourceStatus: 1,
                courseCategoryId: courseForm.category,
                description: courseForm.description,
                topicIds: [],
                introLessons: lessonsUrl,
                courseLevel: mapping(courseForm.difficultyLevel, levels),
                totalHours: 100,
                totalLectures: 16,
                thumbnailURL: coverImgUrl.url,
                languageId: langId,
                courseMode: mapping('Prerecorded', ['Live', 'Prerecorded']),
                amount: showPC ? Number(courseForm.coursePrice) : 0,
                priceCategoryId: 0,
                preRequisite: courseForm.prerequisite,
                objectives: courseForm.objectives,
                courseType: mapping(courseForm.type, [
                    'Single',
                    'One-time',
                    'Multi',
                ]),
                courseVisibility: mapping('Open', ['Open', 'Close']),
                coursePicture: coverImgUrl.url,
            }
            // Loading data to storage for preview
            const relatedData = {
                module: courseForm.modules,
                coverImage: coverImgUrl.url,
            }
            const introLessons = courseData.introLessons
            // Load the data to local storage
            const getStore = localStorage.getItem('Preview')
            const getCourseModules = localStorage.getItem('Course_Related_data')
            if (getStore === null && getCourseModules === null) {
                localStorage.setItem('Preview', JSON.stringify(courseData))
                localStorage.setItem('Course_Related_data', JSON.stringify(relatedData))
                localStorage.setItem('Intro_lessons', JSON.stringify(introLessons))
                setIsloading(false)
            } else {
                localStorage.setItem('Preview', JSON.stringify(courseData))
                localStorage.setItem('Course_Related_data', JSON.stringify(relatedData))
                localStorage.setItem('Intro_lessons', JSON.stringify(introLessons))
                setIsloading(false)
            }
            clearCourseField()
            console.log(courseData)
        } catch (err) {
            // close modal, stop loading, send notification
            setIsloading(false)
            setOpenModal(false)
            openNotification({
                title: 'Introduction lesson creation',
                message: err.message,
                type: 'error'
            })
            console.error(err.message)
        }
    }

    const uploadResourseToS3 = useFileUpload()

    const getAllModules = async () => {
        try {
            const modules = await findModuleByInstructorId(instructorID, 100, 1)
            if (modules.status) {
                setAvailableModules(modules.data)
                setReady(true)
                setFetchingModules(false)
            }
        } catch (error) {
            openNotification({
                title: 'Module retrival',
                type: 'error',
                message: error.message
            })
            setAvailableModules([])
            setReady(false)
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            try {
                const categories = await getCourseCategory()
                const languages = await findAllLanguages()
                setAvailableCategory(categories.data)
                setAvailableLanguages(languages.data)
                // setReady(true)
            } catch (err) {
                console.error(err.message)
            }
        }
        const getPremiumPriceRange = async () => {
            try {
                const res = await getInstructorTierPrice()
                const data = res.data
                for (let i = 0; i < data.length; i++) {
                    if (data[i]?.currency.toUpperCase() === location?.country) {
                        setStartprice(data[i].amountFrom)
                        setEndprice(data[i].amountTo)
                    } else {
                        setStartprice(data[0].amountFrom)
                        setEndprice(data[0].amountTo)
                    }
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        getOptions()
        getPremiumPriceRange()
        if (instructorID) {
            getAllModules()
        }

        return () => {
            setAvailableModules([])
            setAvailableLanguages([])
            setAvailableCategory([])
            setReady(false)
        }
        // eslint-disable-next-line
    }, [instructorID, location.country])

    useEffect(() => {
        const options = []
        for (let i = 0; i < availableModules.length; i++) {
            options.push({ 'lable': availableModules[i].name, 'key': availableModules[i].id, 'value': availableModules[i].name  })
        }
        console.log('what option looks like from module', options)
        setOption(options)
    }, [availableModules])

    // SETTING TITLE AND UPDATING THE STATE BASE ON URL
    useEffect(() => {
        if (loc === '/tutor/edit-course') {
            const page = document.getElementById('form__cont')
            page.scrollIntoView({ behavior: 'smooth' })
            const store = JSON.parse(localStorage.getItem('Preview'))
            const savedModules = JSON.parse(localStorage.getItem('Course_Related_data'))
            const storeModule = []
            if (store !== null && savedModules !== null && ready) {
                setCourseTitle(store.name)
                const courseLang = getCourseLanguageById(availablelanguages, store.languageId);
                setCourseLang(courseLang)
                for (let i = 0; i < store.objectives.length; i++) {
                    setCourseObjectives(store.objectives[i])
                }
                for (let i = 0; i < store.preRequisite.length; i++) {
                    setCoursePrerequisite(store.preRequisite[i])
                }
                setCourseDescription(store.description)
                for (let i = 0; i < store.topicIds.length; i++) {
                    for (let z = 0; z < availableModules.length; z++) {
                        if (store.topicIds[i] === availableModules[z].id) {
                            storeModule.push(availableModules[z].name)
                        }
                    }
                }
                for (let v = 0; v < availableCategory.length; v++) {
                    if (store.courseCategory === availableCategory[v].id) {
                        setCourseCategory(availableCategory[v].name)
                    }
                }
                setCourseModules(savedModules.module)
                const arry = option
                console.log('Edit: Array to hold option', arry)
                // const newOption = arry.filter(itmA => !Object.values(savedModules.module).some(itmB => itmB.key === itmA.key))
                // setOption(prev => prev.filter(itmA => !Object.values(savedModules.module).some(itmB => itmB.key === itmA.key)))
                for (let j = 0; j < arry.length; j++) {
                    for (let i = 1; i <= Object.keys(savedModules.module).length; i++) {
                        if (arry[j]?.key === savedModules.module[i]?.key) {
                            arry.splice(j, Object.keys(savedModules.module).length)
                        }
                    }
                    console.log('Edit: New option array', arry)
                }
                setOption(arry)
                for (let i = 0; i < levels.length; i++) {
                    setCourseDiffLvl(levels[store.courseLevel - 1])
                }
                for (let i = 0; i < ['Single', 'One-time', 'Multi'].length; i++) {
                    setCourseType(['Single', 'One-time', 'Multi'][store.courseType - 1])
                }
                if (store.courseType === 2) {
                    setShowPC(true)
                    setCrsPrice(store.amount)
                    setCoursePrice(store.amount)
                }
                setCoverImg({})
                const introObj = {}
                store.introLessons.sort((a, b) => a.key - b.key)
                store.introLessons.forEach(async (intro) => {
                    console.log(intro)
                    const res = await FindLessonsById(intro.id)
                    introObj[intro.index + 1] = { 'key': intro.index, 'title': res.data.name, 'id': intro.id }
                })
                setIntroLessons(introObj)
            }
        }
        if (loc === '/tutor/create-course') {
            const page = document.getElementById('form__cont')
            page.scrollIntoView({ behavior: 'smooth' })
            if (Object.keys(courseForm.modules).length !== 0) {
                const arry = option
                for (let j = 0; j < arry.length; j++) {
                    for (let i = 1; i <= Object.keys(courseForm.modules).length; ++i) {
                        if (arry[j].key === courseForm.modules[i].key) {
                            arry.splice(j, 1)
                        }
                    }
                    console.log('Course creation option', arry)
                    setOption(arry)
                }
            }
        }

        // eslint-disable-next-line
    }, [ready, option])

    return (
        <div id="form__cont" className="form__cont">
            <h2>{title}</h2>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Form>
                    <div className="form__item__cont">
                        <label>Course Title</label>
                        <Form.Item>
                            <Input
                                onFocus={() => {
                                    if (loc !== '/tutor/create-course') {
                                        getAllModules()
                                    }
                                }}
                                id="courseName"
                                name="course name"
                                placeholder="Name of Course..."
                                value={courseForm.title}
                                onChange={(e) => setCourseTitle(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>What you will learn</label>
                        <Form.Item>
                            <div className="objective_inputfield">
                                <Input
                                    id="setCourseSummary"
                                    name="course summary"
                                    placeholder="What the student will learn"
                                    value={summaryText}
                                    onChange={(e) =>
                                        setSummaryText(e.target.value)}
                                />
                                <button
                                    id="setCourseObjectives"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setCourseObjectives(summaryText)
                                        setSummaryText('')
                                    }}
                                >
                                    Add Item
                                </button>
                            </div>
                        </Form.Item>
                        {courseForm.objectives &&
                            courseForm.objectives.map((text) => (
                                <div
                                    className="text__item"
                                    key={Math.random() * 1000}
                                >
                                    <p>{text}</p>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '1em',
                                            cursor: 'pointer',
                                        }}
                                        className="img__cont"
                                        onClick={() => {
                                            removeFromObj(text)
                                        }}
                                    >
                                        <img src={delIcon} alt="del icon" />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="form__item__cont">
                        <label>Course Description</label>
                        <Form.Item>
                            <TextArea
                                id="textbox"
                                className="textbox"
                                name="Course Summary"
                                placeholder="What does the course involve?"
                                value={courseForm.description}
                                onChange={(e) =>
                                    setCourseDescription(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Prerequisites</label>
                        <Form.Item>
                            <div className="objective_inputfield">
                                <Input
                                    id="prerequisite"
                                    name="Prerequisite"
                                    placeholder="Prior knowledge to have before taking the course"
                                    value={currentText}
                                    onChange={(e) =>
                                        setCurrentText(e.target.value)}
                                />
                                <button
                                    id="setCoursePrerequisite"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setCoursePrerequisite(currentText)
                                        setCurrentText('')
                                    }}
                                >
                                    Add Item
                                </button>
                            </div>
                        </Form.Item>
                        {courseForm.prerequisite &&
                            courseForm.prerequisite.map((text) => (
                                <div
                                    className="text__item"
                                    key={Math.random() * 1000}
                                >
                                    <p>{text}</p>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '1em',
                                            cursor: 'pointer',
                                        }}
                                        id="removeFromPrerequisite"
                                        className="img__cont"
                                        onClick={() => {
                                            removeFromPrequi(text)
                                        }}
                                    >
                                        <img src={delIcon} alt="del icon" />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="form__item__cont">
                        <label>Difficulty level</label>
                        <Form.Item>
                            <Select
                                id="selectDifficulty"
                                placeholder="Select difficulty..."
                                value={courseForm.difficultyLevel}
                                onChange={(value) => {
                                    setCourseDiffLvl(value)
                                }}
                            >
                                <Option id="All" value="All">
                                    <img src={Beginner} alt="level icon" />
                                    &nbsp;&nbsp;
                                    <span>All</span>
                                </Option>
                                <Option id="Beginner" value="Beginner">
                                    <img src={Beginner} alt="level icon" />
                                    &nbsp;&nbsp;
                                    <span>Beginner</span>
                                </Option>
                                <Option id="Intermediate" value="Intermediate">
                                    <img src={Intermediate} alt="level icon" />
                                    &nbsp;&nbsp;
                                    <span>Intermediate</span>
                                </Option>
                                <Option id="Advance" value="Advance">
                                    <img src={Advance} alt="level icon" />
                                    &nbsp;&nbsp;
                                    <span>Advance</span>
                                </Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Course Type</label>
                        <Form.Item
                            id="courseFormType"
                            style={{
                                backgroundColor: '#fdfdfd',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 4px 0px #0000001A',
                            }}
                        >
                            <div className="btn__cont">
                                <button
                                    id="standardCourse"
                                    className={
                                        courseForm.type === 'Single'
                                            ? 'active'
                                            : 'btn'
                                    }
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setShowPC(false)
                                        setCourseType('Single')
                                    }}
                                >
                                    {' '}
                                    General Subscription
                                </button>
                                <button
                                    id="premiumCourse"
                                    className={
                                        courseForm.type === 'One-time'
                                            ? 'active'
                                            : 'btn'
                                    }
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setShowPC(true)
                                        setCourseType('One-time')
                                    }}
                                >
                                    One-time Purchase
                                </button>
                                <button
                                    id="institutionalCourse"
                                    className={
                                        courseForm.type === 'Multi'
                                            ? 'active'
                                            : 'btn'
                                    }
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setShowPC(false)
                                        setCourseType('Multi')
                                    }}
                                >
                                    {' '}
                                    Single Subscription
                                </button>
                            </div>
                        </Form.Item>
                        {showPC && (
                            <>
                                <p
                                    style={{
                                        color: '#828282',
                                        position: 'relative',
                                        left: '3px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Price range for tier 1{' '}
                                    {`[${currencySymbol}${startPrice}-${currencySymbol}${endPrice}]`}
                                </p>
                                <div className="price__cont">
                                    <span>Select course price</span>
                                    <input
                                        id="selectCoursePrice"
                                        className={isError ? 'error' : 'normal'}
                                        type="number"
                                        placeholder="Price"
                                        value={crsPrice}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            const value = e.target.value
                                            setCrsPrice(value)
                                            if (
                                                value >= startPrice &&
                                                value <= endPrice
                                            ) {
                                                setIsError(false)
                                                setCoursePrice(value)
                                            } else {
                                                setIsError(true)
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="form__item__cont">
                        <label>Course category</label>
                        <Form.Item>
                            <Select
                                id="selectCourseCategory"
                                placeholder="Select category..."
                                value={courseForm.category}
                                onChange={(value) => {
                                    setCourseCategory(value)
                                }}
                            >
                                {availableCategory &&
                                    availableCategory.map((category) => (
                                        <Option
                                            key={category.id}
                                            label={category.name}
                                            value={category.id}
                                        >
                                            {category.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="form__item__cont">
                        <label>Cover Image</label>
                        <Form.Item>
                            <div
                                style={{
                                    outline: showMediaOutline
                                        ? '2px solid red'
                                        : '',
                                    cursor: 'pointer',
                                }}
                                className="file__upload"
                            >
                                <h6>Drop file here</h6>
                                <br />
                                <p>or</p>
                                <br />
                                <p
                                    style={{
                                        // paddingInline: '2em',
                                        border: '2px solid #3a86ff',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        borderRadius: '15px',
                                        height: '35px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Select file
                                    <input
                                        type="file"
                                        name="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="file"
                                        title=""
                                        style={{
                                            width: '200px',
                                            position: 'relative',
                                            top: '-30px',
                                            opacity: '0',
                                            cursor: 'pointer',
                                        }}
                                        onChange={(e) => {
                                            const fileSz = byteToMb(
                                                e.target.files[0].size
                                            )
                                            if (fileSz <= 2) {
                                                setCoverImg({
                                                    file: e.target.files[0],
                                                })
                                            } else {
                                                setShowMediaOutline(true)
                                                openNotification({
                                                    title: 'Image file size',
                                                    type: 'error',
                                                    message:
                                                        'Image size too large ( <2mb )',
                                                })
                                                setTimeout(() => {
                                                    setShowMediaOutline(false)
                                                }, 3000)
                                            }
                                        }}
                                    />
                                </p>
                            </div>
                        </Form.Item>
                        {Object.values(courseForm.coverImage).map(
                            (img, idx) => {
                                return (
                                    <Uploadlist
                                        key={idx}
                                        name={img.name}
                                        id="removeCoverImg"
                                        onClick={removeCoverImg}
                                    />
                                )
                            }
                        )}
                    </div>
                    <div className="form__item__cont">
                        <label>Create Introductory Lesson(s)</label>
                        <Collapse id="introItemCont" expandIconPosition="end">
                            {introItem.map((itm, idx) => (
                                <Panel
                                    header={<PaneHeader itm={itm} />}
                                    key={idx}
                                    showArrow="false"
                                >
                                    {itm.children}
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                    <div className="form__item__cont">
                        <label>Language</label>
                        <div className="lang__cont">
                            <h4>Language</h4>
                            <Select
                                className="inner__select"
                                id="setCourseLang"
                                style={{ width: '80%' }}
                                value={courseForm.language}
                                onChange={(value) => {
                                    setCourseLang(value)
                                }}
                            >
                                {availablelanguages &&
                                    availablelanguages.map((lang) => (
                                        <Option
                                            key={lang.id}
                                            value={lang.name}
                                            label={lang.name}
                                        >
                                            {lang.name}
                                        </Option>
                                    ))}
                            </Select>
                        </div>
                    </div>
                    <div className="form__item__cont">
                        <label>Add Modules</label>
                        <p>
                            (Drag and Drop Modules in the order you want them to
                            be presented)
                        </p>
                        <div className="box_container">
                            <Droppable droppableId="left_box" type="Modules">
                                {(provided, snapshot) => (
                                    <div
                                        className={
                                            snapshot.isDraggingOver
                                                ? 'box left_box dragOver'
                                                : 'box left_box'
                                        }
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {(!fetchingModules && option.length < 1) && (
                                            <div className="empty_box">
                                                <h4>
                                                    Instructor has no Module
                                                </h4>
                                                <h4>
                                                    Start by Creating a New
                                                    Module
                                                </h4>
                                            </div>
                                        )}
                                        {fetchingModules &&
                                            <Skeleton count={10} height={40} />}
                                        {(!fetchingModules && option) &&
                                            option.map((opt, idx) => (
                                                <Draggable
                                                    draggableId={opt.key.toString()}
                                                    index={idx}
                                                    key={opt.key}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                snapshot.isDragging
                                                                    ? 'fetched_item dragging'
                                                                    : 'fetched_item'
                                                            }
                                                        >
                                                            {opt.value}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable droppableId="right_box" type="Modules">
                                {(provided, snapshot) => (
                                    <div
                                        className={
                                            snapshot.isDraggingOver
                                                ? 'box right_box dragOver'
                                                : 'box right_box'
                                        }
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {Object.keys(courseForm.modules)
                                            .length < 1 && (
                                            <div className="empty_box">
                                                <h4>
                                                    Drag and drop modules into
                                                    course
                                                </h4>
                                                <div className="img_cont">
                                                    <img
                                                        src={DropIcon}
                                                        alt="drop icon"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {courseForm.modules &&
                                            Object.keys(courseForm.modules).map(
                                                (order, idx) => (
                                                    <Draggable
                                                        draggableId={courseForm?.modules?.[
                                                            order
                                                        ]?.key.toString()}
                                                        index={idx}
                                                        key={
                                                            courseForm
                                                                ?.modules?.[
                                                                    order
                                                                ]?.key
                                                        }
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                className={
                                                                    snapshot.isDragging
                                                                        ? 'box_item dragging'
                                                                        : 'box_item'
                                                                }
                                                            >
                                                                {!snapshot.isDragging && (
                                                                    <div className="order">
                                                                        {order}.
                                                                    </div>
                                                                )}{' '}
                                                                <div
                                                                    {...provided.dragHandleProps}
                                                                    className={
                                                                        snapshot.isDragging
                                                                            ? 'item_name dragging'
                                                                            : 'item_name'
                                                                    }
                                                                >
                                                                    <span className="first">
                                                                        {
                                                                            courseForm
                                                                                ?.modules?.[
                                                                                    order
                                                                                ]
                                                                                ?.value
                                                                        }
                                                                    </span>{' '}
                                                                    {!snapshot.isDragging && (
                                                                        <span
                                                                            className="del_item"
                                                                            onClick={
                                                                                handleDel
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    CancelIcon
                                                                                }
                                                                                alt="del"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <div className="form__item__cont">
                        <Checkbox
                            id="setCheckbox1"
                            onChange={(e) => {
                                setChckBx1(e.target.checked)
                            }}
                        >
                            I have read and agree to the terms and conditions of
                            service.
                        </Checkbox>
                    </div>
                    <div className="form__item__cont">
                        <Checkbox
                            id="setCheckbox2"
                            onChange={(e) => {
                                setChckBx2(e.target.checked)
                            }}
                        >
                            I take sole responsibility of the content of the
                            courses I have created. We will not be liable for
                            any damage or harm or misinformation resulting from
                            any of the contents in your courses. We reserve the
                            right to monitor and take action to restrict access
                            or availability of any material considered obscene,
                            lascivious or objectionable.
                        </Checkbox>
                    </div>
                    <div className="btn__cont">
                        <Button
                            id="ClearAll"
                            onClick={() => {
                                setShowPC(false)
                                clearCourseField()
                            }}
                            type="primary"
                        >
                            Clear all
                        </Button>
                        <Button
                            disabled
                            // Latest update, "save and exit button doesn't do the work of the submit button"
                        >
                            Save and exit
                        </Button>
                    </div>
                    <div className="btn__cont">
                        <Button
                            style={{
                                marginLeft: '30%',
                            }}
                            id="Preview"
                            type="primary"
                            onClick={() => {
                                setIsloading(true)
                                const cont = validateForm()
                                if (cont) {
                                    // link to preview page, wanna use react router
                                    setData()
                                    setOpenModal(true)
                                } else {
                                    setIsloading(false)
                                }
                            }}
                        >
                            Preview
                        </Button>
                    </div>
                    <Modal
                        centered
                        id="ModalCourseCreation"
                        footer={null}
                        open={openModal}
                        // open={true}
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <div>
                            <h4 style={{ color: '#3a86ff' }}>
                                {isLoading
                                    ? 'Preparing Course For Preview'
                                    : 'Course Is Set For Preview'}
                            </h4>
                            <div style={{ marginBlock: '2em' }}>
                                {isLoading && <RequestLoader />}
                                {!isLoading && (
                                    <img src={DoneLoading} alt="done_loading" />
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Link
                                    id="ToPreviewCourse"
                                    to="/tutor/preview-course"
                                >
                                    <Button
                                        /** button to submit the form */
                                        key={Math.random() * 100}
                                        size="middle"
                                        disabled={isLoading && true}
                                        id="GoToPreviewPage"
                                        type="primary"
                                        style={{
                                            borderRadius: '10px',
                                            paddingInline: '2em',
                                            // marginLeft: '200px',
                                            height: '40px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setOpenModal(false)
                                        }}
                                    >
                                        {isLoading
                                            ? 'Uploading resources...'
                                            : 'Go to Preview Page'}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Modal>
                </Form>
            </DragDropContext>
        </div>
    )
}
