import DashboardIcon from '../assets/icons/dashboard_icon.svg'
import CoursesIcon from '../assets/icons/courses_icon.svg'
// import ApplicationsIcon from '../assets/icons/applications_icon.svg'
// import CourseReportsIcon from '../assets/icons/course_reports_icon.svg'
// import UserReportsIcon from '../assets/icons/user_reports_icon.svg'
// import InstructorOverviewIcon from  '../assets/icons/instructor-overview-icon.svg'
// import SupportRequestIcon from '../assets/icons/support-requests-icon.svg'

export const adminDashboardRoutes = [
    {
        match: '/admin',
        path: '/admin',
        title: 'Dashboard',
        display: 'Dashboard',
        icon: DashboardIcon,
    },
    {
        match: '/admin/courses',
        path: '/admin/courses',
        title: 'Courses ',
        display: 'Courses Review Overview',
        icon: CoursesIcon,
    },
    // {
    //     match: '/admin/applications',
    //     path: '/admin/applications',
    //     title: 'Applications',
    //     icon: ApplicationsIcon,
    // },
    // {
    //     match: '/admin/instructor-overview',
    //     path: '/admin/instructor-overview',
    //     title: 'Instructor Overview',
    //     icon: InstructorOverviewIcon,
    // },
    // {
    //     match: '/admin/user-reports',
    //     path: '/admin/user-reports',
    //     title: 'User Reports',
    //     icon: UserReportsIcon,
    // },
    // {
    //     match: '/admin/support-requests',
    //     path: '/admin/support-requests',
    //     title: 'Support Requests',
    //     icon: SupportRequestIcon,
    // },
    // {
    //     match: '/admin/course-reports',
    //     path: '/admin/course-reports',
    //     title: 'Course Reports',
    //     icon: CourseReportsIcon,
    // },
]
