import React, { useState, useEffect } from 'react'

import './subscription.scss'
import { Button } from '../../../../components'
import { getAllPlans } from '../../../../services'
import { openNotification } from '../../../../utils/helpers'
import { StripeApp } from './Stripe/stripe'
import Naira from './assets/naira.png'
import Pound from './assets/pound.png'
import { useLocation } from '../../../../hooks'

export const Subscription = () => {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [paymentModal, setPaymentModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(undefined)
    // const [loading, setLoading] = useState(false)
    // const [paymentLoading, setPaymentLoading] = useState(false)
    const { state: location } = useLocation();

    const getSubscriptions = async () => {
        // setLoading(true)
        try {
            const response = await getAllPlans()
            setSubscriptionPlans(response)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        // setLoading(false)
    }

    useEffect(() => {
        getSubscriptions()
    }, [])

    const handlePayment = async (plan) => {
        // setPaymentLoading(true)
        setSelectedPlan(plan)
        setPaymentModal(true)
        // setPaymentLoading(false)
    }

    if (paymentModal) {
        return (
            <StripeApp
                plan={selectedPlan}
                visible={paymentModal}
                close={() => setPaymentModal(false)}
            />
        )
    }

    const symbol = () => {
        if (location.country_code === 'NG') {
            return <img className="naira" src={Naira} alt="" />
        } else if (location.country_code === 'GB') {
            return <img className="pounds" src={Pound} alt="" />
        }
    }

    // if (paymentLoading) {
    //     return <Loading />
    // }

    // if (paymentModal) {
    //     return (
    //         <StripeApp
    //             plan={selectedPlan}
    //             visible={paymentModal}
    //             close={() => setPaymentModal(false)}
    //         />
    //     )
    // }
    return (
        <section id="pricing">
            <div>
                <div className="container">
                    <h2>Pricing</h2>
                </div>

                <div className="pricing-container">
                    <div className="card left-card">
                        {subscriptionPlans.slice(0, 1).map((plan) => (
                            <div key={plan.id} className="header">
                                <h4><span>7 Day </span>{plan.name}</h4>
                                <h4>{symbol()}{plan.amount}</h4>

                                <div className="wrapper">
                                    <ul className="features">
                                        <li className="left-feature">Montes sed egestas lacus.</li>
                                        <li className="left-feature">Montes sed egestas lacus.</li>
                                        <li className="left-feature">Montes sed egestas lacus.</li>
                                    </ul>
                                </div>
                                <Button
                                    onClick={() => handlePayment(plan)}
                                    className="btn"
                                >
                                    Get Started
                                </Button>
                            </div>
                        ))}
                    </div>

                    <div className="card center-card">
                        {subscriptionPlans.slice(1, 2).map((plan) => (
                            <div key={plan.id} className="header">
                                <h4>{plan.name}</h4>
                                <h4>{symbol()}{plan.amount}</h4>

                                <div className="wrapper">
                                    <ul className="features">
                                        <li className="center-feature">Montes sed egestas lacus</li>
                                        <li className="center-feature">Montes sed egestas lacus</li>
                                        <li className="center-feature">Montes sed egestas lacus</li>
                                    </ul>
                                </div>
                                <Button
                                    onClick={() => handlePayment(plan)}
                                    className="btn"
                                >
                                    Get Started
                                </Button>
                            </div>
                        ))}
                    </div>

                    <div className="card right-card">
                        {subscriptionPlans.slice(2, 3).map((plan) => (
                            <div key={plan.id} className="header">
                                <h4>{plan.name}</h4>
                                <h4>{symbol()}{plan.amount}</h4>

                                <div className="wrapper">
                                    <ul className="features">
                                        <li className="right-feature">Montes sed egestas lacus.</li>
                                        <li className="right-feature">Montes sed egestas lacus.</li>
                                        <li className="right-feature">Montes sed egestas lacus.</li>
                                    </ul>
                                </div>
                                <Button
                                    onClick={() => handlePayment(plan)}
                                    className="btn"
                                >
                                    Get Started
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}