import { useHistory } from 'react-router-dom';
import { DownloadHanbook } from '../../../../utils/downloadHandbook';
import './heroInstructor.scss';

const HeroInstructor = () => {
    const history = useHistory()

    return (
        <section id="heroInstructor">
            <div className="background">
                <div className="container-hero">
                    <h1 className="title">Become an Instructor</h1>
                    <p className="info-text">The biggest quest to learn anything is curiosity. Join us and kindle the fire of curiosity and learning in our learners. Join us as we inspire our members to lead, grow and care.
                    </p>
                    <div className="buttons">
                        <button
                            className="get-started"
                            onClick={() => {
                                history.push('/me/instructor-onboarding-application')
                            }}
                        >Get Started
                        </button>
                        <button
                            className="instructor-handbook"
                            onClick={DownloadHanbook}
                        >Instructor Handbook
                        </button>
                        {/* <form action="">
                            <Link
                                // to="me/become-an-instructor"
                                to="me/instructor-onboarding-application"
                            >
                                <input
                                    className="get-started"
                                    type="submit"
                                    value="Get started"
                                />
                            </Link>
                        </form>
                        <input
                            className="instructor-handbook"
                            type="submit"
                            value="Instructor Handbook"
                            onClick={handleDownloadHandbook}
                        /> */}
                    </div>
                </div>
            </div>
        </section>
    )
};

export default HeroInstructor;
