import { useState, useEffect } from 'react'
import { Dropdown, Menu } from 'antd'

import Review from '../../assets/unreviewed-icon.svg'
import Approved from '../../assets/approved-icon.svg'
import Denied from '../../assets/denied-icon.svg'
import './style.scss'

export const ReviewDropdown = (initVal) => {
    const [approved, setApproved] = useState([])

    const setApprovedHandler = (value) => {
        setApproved(value)
    }

    useEffect(() => {
        setApproved(initVal.initVal)
    }, [initVal])
    const menu = (
        <Menu>
            <Menu.Item key="0">
                <button onClick={() => setApprovedHandler(1)}>
                    <img src={Approved} alt="Click here to appove" />
                </button>
            </Menu.Item>
            <Menu.Item key="1">
                <button onClick={() => setApprovedHandler(2)}>
                    <img src={Denied} alt="Click here to deny" />
                </button>
            </Menu.Item>
            <Menu.Item key="2">
                <button onClick={() => setApprovedHandler(3)}>
                    <img src={Review} alt="Click here to set as un reviewed" />
                </button>
            </Menu.Item>
        </Menu>
    )

    const defualtImg = (val) => {
        if (val === 1) {
            return (
                <Dropdown menu={menu}>
                    <img src={Approved} alt="Click here to review" />
                </Dropdown>
            )
        } else if (val === 2) {
            return (
                <Dropdown menu={menu}>
                    <img src={Denied} alt="Click here to review" />
                </Dropdown>
            )
        } else if (val === 3) {
            return (
                <Dropdown menu={menu}>
                    <img src={Review} alt="Click here to review" />
                </Dropdown>
            )
        }
    }

    return <div className="reviewDropdown">{defualtImg(approved)}</div>
}
