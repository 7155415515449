import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom'
import { Lessoncard } from '../../MyCourses/courseCard/index'
import SearchIcon from '../../../../assets/icons/search.svg'
import { useInstructorData } from '../../../../hooks';
import { RequestLoader } from '../../../../components/Loading'
// import { DownOutlined } from '@ant-design/icons';
// import { FindLessonsByInstructorId } from '../../../../services'
// import axios from 'axios';

export function LessonSecCont() {
    const {
        state: { isLoading, lessons, instructorID }
    } = useInstructorData()

    const [list, setList] = useState([])
    const [inputValue, setInputvalue] = useState('')
    const [searchParam, setSearchParam] = useState('')
    const [error, showError] = useState(false)
    // const onChange = (e) => {
    //     const val = e.target.checked
    //     // item has to be checked before been added to list
    //     if (e.target.checked) {
    //         // check of value exist in list
    //         list.includes(val) ? console.log('value exist already') : setList([...list, val])
    //     } else {
    //         // remove from the list, utilizing the return array of filter to update prevArry
    //         const newList = list.filter(lst => lst !== val)
    //         setList(newList)
    //     }
    // };
    // const menu =
    //     <Menu
    //         items={[
    //             { key: 'active', label: (<Checkbox value="active" onChange={onChange}>Active lesson</Checkbox>) },
    //             { key: 'review', label: (<Checkbox value="review" onChange={onChange}>In-review lesson</Checkbox>) },
    //             { key: 'SIC', label: (<Checkbox value="SIC" onChange={onChange}>Still-in-creation lesson</Checkbox>) },
    //             { key: 'retired', label: (<Checkbox value="retired" onChange={onChange}>Retired lesson</Checkbox>) },
    //         ]}
    //     >.
    //     </Menu>
    const handleSearch = (param) => {
        if (param === '') {
            showError(false)
            setList(lessons)
        } else {
            const searchResult = lessons.filter((lesson) => lesson.name.toLowerCase().includes(param.toLowerCase()))
            if (searchResult.length < 1) {
                showError(true)
                setList(searchResult)
            } else {
                showError(false)
                setList(searchResult)
            }
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchParam(inputValue)
        }, 1500)

        return () => clearTimeout(timerId)
    }, [inputValue])

    useEffect(() => {
        handleSearch(searchParam)
        // eslint-disable-next-line
    }, [searchParam])

    useEffect(() => {
        if (instructorID) {
            setList(lessons)
        }
        return () => {
            setList([])
        }
    }, [instructorID, lessons])

    return (
        <section>
            {/* <div className="filter__section">
                <p>Filter by:</p>
                <Dropdown id="filter" trigger={['click']} overlay={menu}>
                    <p>
                        Status <DownOutlined />
                    </p>
                </Dropdown>
            </div> */}
            <div className="search__et__create">
                <div className="input__cont">
                    <Input
                        placeholder="Input search text"
                        value={inputValue}
                        onChange={(e) => setInputvalue(e.target.value)}
                    />
                    <div
                        onClick={() => handleSearch(inputValue)}
                        className="search__cont"
                    >
                        <img src={SearchIcon} alt="search Icon" />
                    </div>
                </div>
                <Link to="/tutor/create-lesson">
                    <Button className="create_btn" type="primary">
                        {' '}
                        Create Lesson
                    </Button>
                </Link>
            </div>
            <div className="card__wrapper">
                {isLoading && (
                    <div className="loader_cont">
                        <RequestLoader />
                    </div>
                )}
                {!isLoading && list.length < 1 && !error && (
                    <div className="empty_data">
                        <h4>You have not created your first lessons yet.</h4>
                        <h4>Click on create lessons to start now</h4>
                    </div>
                )}
                {!isLoading &&
                    list.length >= 1 &&
                    list.map((listCont, idx) => {
                        return <Lessoncard key={idx} type={listCont} />
                    })}
                {error &&
                    <div className="empty_data">
                        <h4>Lessons with name {inputValue} does not exist</h4>
                        <h4>Click on create lessons to start now</h4>
                    </div> }
            </div>
        </section>
    )
}