import React from 'react'
import ReactPlayer from 'react-player/lazy'
import Image1 from './assets/Course structure 1.svg'

export const BestPracticesForCreatingQuiz = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>
                Best Practices For Creating Quiz
            </h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>

            <section style={{ width: '100%' }} id="guide_overview">
                <div style={{ margin: '20px 0px' }}>
                    {' '}
                    To help you create and organize your courses effectively,
                    here's a simple guide to understand our basic structure.
                </div>
                <h2 style={{ marginBottom: '10px' }}>
                    What is a Course Structure?
                </h2>
                <p>
                    A course structure is a way to organize your course content
                    into a clear, logical format.
                </p>
                <p>
                    At Zustech, we use a hierachy that consists of Courses,
                    Modules and Lessons.
                </p>
                <ol style={{ margin: '20px 0px' }}>
                    <li>
                        Course: The highest level of organization. A Course
                        covers an entire subject or a broad topic
                    </li>
                    <li>Module: These are like chapters in a book</li>
                    <li>
                        Lesson: These are individual topics or units within each
                        module.
                    </li>
                </ol>

                <h3 style={{ marginTop: '20px' }}>Think of it as a tree</h3>
                <p>Course (The whole tree)</p>
                <p>Module (The branches)</p>
                <p>Lesson (The leaves)</p>

                <h3 style={{ marginTop: '20px' }}>For Example:</h3>
                <p>Course: Anatomy 102</p>

                <p>Module 1: Introduction</p>
                <p>Lesson 1: Bones of the head</p>
                <p>Lesson 2: The skull</p>

                <div style={{ width: '90%' }}>
                    <img style={{ width: '100%' }} src={Image1} alt="" />
                </div>
            </section>
        </>
    )
}
