export const revenueData = [
    {
        'id': 1,
        'title': 'Total Revenue',
        'value': 300000,
        'showCurrency': true,
    },
    {
        'id': 2,
        'title': 'Total no. of instructors',
        'value': 500,
        'showCurrency': false,
    },
    {
        'id': 3,
        'title': 'Total no. of signed up student',
        'value': 30000,
        'showCurrency': false,
    },
]

export const courseRevenueInfo = [
    {
        'id': 1,
        'title': 'Multi-course subscription',
        'subTitle': 'Total no. of subscribed students',
        'value': 5600,
    },
    {
        'id': 2,
        'title': 'Single-course subscription',
        'subTitle': 'Total no. of subscribed students',
        'value': 5600,
    },
    {
        'id': 3,
        'title': 'One-time purchase course',
        'subTitle': 'Total no. of Purchases',
        'value': 2400,
    },
]

export const mimickedCourseInfo = [
    {
        id: 1,
        name: 'UI/UX Design Masterclass',
        value: '3',
        type: 1,
        revenue: '320000',
        count: '300000'
    },
    {
        id: 2,
        name: 'Python Masterclass',
        value: '14',
        type: 2,
        revenue: '280000',
        count: '20000'
    },
    {
        id: 3,
        name: 'Web Development Masterclass',
        value: '20',
        type: 3,
        revenue: '200000',
        count: '10000'
    },
    {
        id: 4,
        name: 'Jira Masterclass',
        value: '20',
        type: 3,
        revenue: '160000',
        count: '8000'
    },
    {
        id: 5,
        name: 'SQL Masterclass',
        value: '2',
        type: 2,
        revenue: '116000',
        count: '58000'
    },
    {
        id: 6,
        name: 'HTML Masterclass',
        value: '20',
        type: 3,
        revenue: '60000',
        count: '3000'
    }
]

export const mimickTopInstructor = [
    {
        id: 1,
        name: 'John Doe',
        email: 'johnnydoe234@gmail.com',
        review: '3000',
        avgStar: 3.5
    },
    {
        id: 2,
        name: 'Eckhart Tolle',
        email: 'eckharttolle234@gmail.com',
        review: '4500',
        avgStar: 5
    },
    {
        id: 3,
        name: 'Rane Doe',
        email: 'janezdoe34@gmail.com',
        review: '2000',
        avgStar: 4
    },
]