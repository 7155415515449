import React from 'react'
import ReactPlayer from 'react-player/lazy'

export const UploadingCourses = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>Uploading Courses</h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>

            <section style={{ width: '100%' }} id="guide_overview">
                <div style={{ margin: '20px 0px' }}>
                    It is advisable that your start uploading the course and
                    adding the modules to the course immediately after adding
                    your lessons into modules (Module creation). Using the
                    outline you've created, Start creating modules and adding
                    lessons in the organized order you have outlined.
                </div>

                <p>
                    Login in to our system and on the instructor dashboard,
                    select 'Create a New Module'
                </p>
                <p>Go to the module section.</p>
                <p style={{ padding: '0 0 40px 0' }}>
                    <strong>On the module section,</strong>
                </p>
            </section>
        </>
    )
}
