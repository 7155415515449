import React from 'react'
import { Select } from 'antd'
// import { DownOutlined } from '@ant-design/icons'

export const AdminFilter = ({ value, setValue }) => {
    // const [showMenu, setShowMenu] = useState(false)
    const { Option } = Select

    // const courseIntialState = useRef(courses)

    const handleChange = (e) => {
        setValue(e)
    }

    const selectOptions = [
        {
            id: 1,
            label: 'All',
            value: 'All',
        },
        {
            id: 2,
            label: 'Last 30 days',
            value: 'Last 30 days',
        },
        {
            id: 3,
            label: 'Last 3 months',
            value: 'Last 3 months',
        },
        {
            id: 4,
            label: 'Last 6 months',
            value: 'Last 6 months',
        },
        {
            id: 5,
            label: 'Last 1 year',
            value: 'Last 1 year',
        },
    ]
    return (
        <div id="admin_filter">
            <div className="filter_menu">
                <Select
                    defaultValue={value}
                    style={{ width: 120 }}
                    onChange={handleChange}
                >
                    {selectOptions.map((opt) => <Option key={opt.id} value={opt.value}>{opt.label}</Option>)}
                </Select>
            </div>
        </div>
    )
}
