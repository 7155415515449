import { Swiper, SwiperSlide } from 'swiper/react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
// import Quote from "../Testimonial/images/quote.png";
// import Closequote from "../Testimonial/images/closequote.png";
import Group from './images/Group.png'
// import Photo from './images/photo.png'
import './Testimonial.scss'
import people from './data'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export const Testimonial = ({ title }) => {
    return (
        <section id="Testimonials">
            <div className="testimonial-section container">
                <h3 className="title">
                    {title === undefined ? 'TESTIMONIALS' : title}
                </h3>
                <div className="img_cont top">
                    <div className="img-cont">
                        <img className="group" src={Group} alt="" />
                    </div>
                </div>
                <div className="container-btn">
                    <div className="prev-featured">
                        <FaChevronLeft
                            className="previous"
                            size={26}
                            style={{ fill: 'black' }}
                        />
                    </div>

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                        navigation={{
                            nextEl: '.next-featured',
                            prevEl: '.prev-featured',
                        }}
                    >
                        {/* looping through my array object to fetch data */}

                        {people.map((user) => (
                            <SwiperSlide key={user.id}>
                                <div className="slide-content">
                                    <div className="box-section">
                                        <p className="user-testimonial">
                                            {user.testimonial}
                                        </p>
                                        {/* <img
                                            src={Photo}
                                            alt=""
                                            className="user-photo"
                                        /> */}
                                        <h5>{user.username}</h5>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="next-featured">
                        <FaChevronRight
                            className="next"
                            size={26}
                            style={{ fill: 'black' }}
                        />
                    </div>
                </div>
                <div className="img_cont bottom">
                    <div className="img-cont">
                        <img className="group-fade" src={Group} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
