import ReactDOM from 'react-dom'
import React from 'react'
// import { Provider } from 'react-redux'
import { CartState } from './context/Cart/cartState'
import App from './App'

import './index.scss'
// import i18n:
import './services/i18n'
// import { CartState } from './context/Cart/cartState';
import { Root } from './store'

ReactDOM.render(
    // <React.StrictMode>
    <Root>
        <CartState>
            <App />
        </CartState>
    </Root>,
    // </React.StrictMode>,
    document.getElementById('root')
)
