import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../../Home/styles.scss';

ChartJS.defaults.datasets.bar.showBorder = false
ChartJS.defaults.datasets.bar.borderColor = 'rgba(75,192,192,1)'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
);

const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: true, // Allow the chart to scale freely with the container
    scales: {
        x: {
            display: true, // Hides x-axis and its labels
            grid: {
                display: true, // Hides grid lines
            },
        },
        y: {
            display: true, // Hides y-axis and its labels
        },
    },
    plugins: {
        legend: {
            display: false, // Hides legend if not needed
        },
    },
}

const data = {
    labels: [
        'Jan 1',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Jan 31',
    ],
    datasets: [
        {
            id: 1,
            label: 'mins',
            tension: 0.6,
            borderWidth: 2,
            borderColor: '#A6CEE3',
            pointRadius: 1,
            pointHoverRadius: 2,
            data: [
                45, 57, 50, 30, 3, 60, 46, 25, 55, 42, 43, 42.5, 34, 36, 60, 46,
                58, 46, 33, 32, 14, 45, 40, 51, 47, 35, 60, 20, 52, 50,
            ],
        },
    ],
}

export const ActiveUserChart = () => {
    return (
        <div id="active-user-chart" data-testid="active-user-chart">
            <Line data={data} options={options} />
        </div>
    )
}