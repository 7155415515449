import { request as RQ } from '../utils'

export const getQuestionByTopicId = async (TopicId) => {
    try {
        const url = `/Question/findQuestionByTopicId`
        const request = await RQ
        const response = await request.get(url, {
            params: {
                TopicId,
            },
        })
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}
