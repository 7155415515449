import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
);

export const options = {
    maintainAspectRatio: true, // Allow the chart to scale freely with the container
    scales: {
        x: {
            display: true, // Hides x-axis and its labels
            grid: {
                display: true, // Hides grid lines
            },
            ticks: {
                autoSkip: false,
                min: 0,
                stepSize: 20,
            },
        },
        y: {
            display: true, // Hides y-axis and its labels
            grid: {
                display: false, // Hides grid lines
            },
        },
    },
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        title: {
            display: false,
            text: 'Chart.js Horizontal Bar Chart',
        },
    },
}

const labels = ['Jan 1', '', '', '', '', '', 'Jan 31']

export const data = {
    labels,
    datasets: [
        {
            data: [40, 90, 30, 20, 60, 10, 80],
            backgroundColor: 'green',
            borderWidth: 0,
            barThickness: 8,
            borderSkipped: 'bottom',
            borderRadius: 20
        },
    ],
};

export function Coursebarchart() {
    return <Bar options={options} data={data} />;
}