// import { useState } from 'react';
// import axios from 'axios'
// import currencySymbolMap from 'currency-symbol-map'
import { StripeSupported } from '../pages/landing/pricing/Payment/currencySymbols';

export const fetchUserLocation = async () => {
    // const [location, setLocation] = useState(null)
    // const [userIP, setUserIP] = useState(null)
    // const [city, setCity] = useState(null)
    // const [region, setRegion] = useState(null)
    // const [country, setCountry] = useState(null)
    // localStorage.setItem('currentCountry', country)

    // Only run on mount and unmount
    // const setValues = (json) => {
    //     setLocation(json)
    //     setUserIP(json.ip)
    //     setCity(json.city)
    //     setRegion(json.region)
    //     setCountry(json.country)
    // }

    // setValues({})
    // Fetch location data from ipapi.co when component mounts
    // 'https://ipinfo.io/json?token=011f0703627471' 'https://ipapi.co/json'
    // fetch('https://ipinfo.io/json?token=011f0703627471')
    //     .then((data) => data.json())
    //     .then((json) => {
    //         console.log(json)
    //         setValues(json)
    //     })
    //     .catch((err) => console.error(err))
    // useEffect(() => {

    //     return () => {
    //         setLocation(null)
    //         setUserIP(null)
    //         setCity(null)
    //         setRegion(null)
    //         setCountry(null)
    //     }
    // }, [])

    // return {
    //     location,
    //     userIP,
    //     city,
    //     region,
    //     country,
    // }
    // Check if the location is already cached in localStorage
    // const cachedLocation = localStorage.getItem('zustech:user-location');

    // if (cachedLocation) {
    //     return JSON.parse(cachedLocation); // Return cached location
    // }
    const allowedCountries = []
    for (let i = 0; i < StripeSupported.length; i++) {
        allowedCountries.push(StripeSupported[i].countryCode)
    }
    // If not cached, fetch from the API
    try {
        const response = await fetch('https://ipinfo.io/json?token=011f0703627471');
        const data = await response.json();
        // Cache the fetched location in localStorage
        localStorage.setItem('zustech:user-location', JSON.stringify(data));
        if (allowedCountries.includes(data.country)) {
            localStorage.setItem('currentCountry', 'GB')
        } else {
            localStorage.setItem('currentCountry', 'NG')
        }
        return data;
    } catch (error) {
        console.error('Error fetching country code:', error);
        throw error;
    }
};
