import React from 'react'
import { Twirl as Hamburger } from 'hamburger-react'
import { CartIcon } from '../../../PremiumCourse/CartIcon'
import './Header.scss'
// import { useAuth } from '../../../../hooks'
// import { Avatar } from 'antd'
// import DisplayPhoto from '../../../../assets/icons/Ellipse.svg'
// import { NavLink, useHistory } from 'react-router-dom'
// import ArrowRight from '../../../../assets/icons/arrow_right.svg'
// import Notify from '../../../../assets/icons/fi_bell.svg'
// import Message from '../../../../assets/icons/messageIcon.svg'
// import { findInstructorByUserId } from '../../../../services' useInstructorData

export const Adminheader = (props) => {
    const { isOpen, setOpen, setShowSideBar, title } = props
    // const [isInstructor, setIsInstructor] = useState(null)

    // const {
    //     state: { data: user },
    // } = useAuth()

    // const profilePic = () => {
    //     if (user?.profilePic) {
    //         return <Avatar size="large" id="profilePic" src={user.profilePic} />
    //     } else {
    //         return <img src={DisplayPhoto} alt="Profile pics" />
    //     }
    // }
    // const [instructorApplication, setinstructorApplication] = useState({
    //     link: '',
    //     name: '',
    // })
    // const [applicationStatus, setApplictionStatus] = useState(null)

    // const history = useHistory()

    // const { getInstructorID } = useInstructorData()

    // const regEx =
    //     /^\/me(\/[a-z]+-?[a-z]+?)?(\/[a-z]+?-?[a-z]?(\d+)?)?(\/(\d+)?([a-z]+)?-?([a-z]+)?)?$/gi
    // know the current page been rendered base on the url
    // const loc = document.location.pathname

    // useEffect(() => {
    //     const sendRequest = async () => {
    //         try {
    //             getInstructorID(user?.id)
    //             const res = await findInstructorByUserId(user?.id)
    //             if (res.status && res.data.instructorApplicationStatus === 1) {
    //                 setIsInstructor(true)
    //                 setApplictionStatus(res.data.instructorApplicationStatus)
    //             } else {
    //                 setIsInstructor(false)
    //                 setApplictionStatus(res.data.instructorApplicationStatus)
    //             }
    //         } catch (error) {
    //             console.error(error.message)
    //             setIsInstructor(false)
    //             setApplictionStatus(2)
    //         }
    //     }
    //     if (isLoggedIn) {
    //         sendRequest()
    //     }
    //     return () => {
    //         setIsInstructor(null)
    //         setApplictionStatus(null)
    //     }

    //     // eslint-disable-next-line
    // }, [user?.id])

    // CHECK IF USER IS AN INSTRUCTOR OR NOT
    // useEffect(() => {
    //     const getStatus = () => {
    //         if (isInstructor && applicationStatus === 1 && regEx.test(loc)) {
    //             // If user is an Instructor, application has been approved and is in user's dashboard
    //             setinstructorApplication({
    //                 link: '/tutor',
    //                 name: 'Switch to Instructor Dashboard',
    //                 id: 'switch-to-instructor',
    //             })
    //         } else if (
    //             isInstructor &&
    //             applicationStatus === 1 &&
    //             !regEx.test(loc)
    //         ) {
    //             // If user is an Instructor, application has been approved and is not in user's dashboard
    //             setinstructorApplication({
    //                 link: '/me',
    //                 name: 'Switch to Student Dashboard',
    //                 id: 'switch-to-student',
    //             })
    //         } else if (
    //             isInstructor === false &&
    //             applicationStatus > 1 &&
    //             regEx.test(loc)
    //         ) {
    //             // if user is not an instructor and is not in user's dashboard
    //             setinstructorApplication({
    //                 link: '/me/instructor-onboarding-application',
    //                 name: 'Become an instructor',
    //                 id: 'become-an-instructor',
    //             })
    //         } else if (
    //             isInstructor === false &&
    //             applicationStatus !== 1 &&
    //             !regEx.test(loc)
    //         ) {
    //             // if user is an instructor and is not in user's dashboard
    //             setinstructorApplication({
    //                 link: '/me/instructor-onboarding-application',
    //                 name: 'Become an instructor',
    //                 id: 'become-an-instructor',
    //             })
    //             history.push('/me')
    //         }
    //     }
    //     getStatus()
    //     // eslint-disable-next-line
    // }, [isInstructor, applicationStatus])

    return (
        <div id="adminheader">
            <div className="first_Section">
                <Hamburger
                    // className="hamburger-react"
                    color="#3a86ff"
                    direction="left"
                    label="Show menu"
                    toggled={isOpen}
                    toggle={setOpen}
                    onToggle={(toggled) => {
                        if (toggled) {
                            // open a menu
                            setShowSideBar(true)
                        } else {
                            // close a menu
                            setShowSideBar(false)
                        }
                    }}
                />
                <div className="detail">
                    <div className="Header__detail">
                        <h5 data-testid="dashboard-header" id="header">
                            {title}
                        </h5>
                        <p data-testid="main-subheading">
                            Welcome to the admin dashboard
                        </p>
                    </div>
                </div>
            </div>

            <div className="Header__CTA">
                <div className="icon__cont">
                    {/* <img src={Notify} alt="notification" />
                    <img src={Message} alt="message" /> */}
                    <CartIcon />
                </div>
            </div>
        </div>
    )
}