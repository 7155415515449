import React from 'react'
import ReactPlayer from 'react-player/lazy'
import imageUploadlesson from './assets/uploading lessons 1.svg'

export const UploadingYourLessons = () => {
    // const [coverImg, setCoverImg] = useState(
    //     'https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg'
    // )
    return (
        <>
            <h2 style={{ margin: '20px 0px' }}>Uploading Your Lessons</h2>
            <div className="video_cont" id="video_cont">
                <ReactPlayer
                    width="100%"
                    height="500px"
                    playing={true}
                    // You can pass the thumbnail to the light attribute, this will be the coursePicture
                    // light={<img src={coverImg} alt="Thumbnail" />}
                    url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                />
            </div>

            <div>
                <p style={{ padding: '25px 0' }}>
                    It is advisable that before you start uploading lessons in
                    our system, all your lesson contents/videos for the course
                    have been recorded and properly organized. It is important
                    you have all your course materials ready before deciding to
                    upload them into our system.
                </p>

                <p>
                    The following information will be required of you to be able
                    to upload your lesson:
                </p>
                <ul style={{ padding: '0 0 20px 30px' }}>
                    <li>Lesson title.</li>
                    <li>What you will learn.</li>
                    <li>Lesson description.</li>
                    <li>Lesson Language.</li>
                    <li>Media (Video content).</li>
                    <li>Lesson document (materials/resources).</li>
                </ul>

                <div style={{ width: '100%', marginBottom: '30px' }}>
                    <img style={{ width: '90%', margin: '0 auto' }} src={imageUploadlesson} alt="uploadlesson-guide" />
                </div>

                <h3>How to Upload your Lessons</h3>
                <p>
                    Log in to our system and on the instructor dashboard, click
                    on <strong>"Create New Course"</strong>. You will be taken
                    to the course creation page and you can start uploading your
                    lessons in the lesson section of the page.
                </p>

                <p>Carefully and correctly input your already written out:</p>
                <ul style={{ marginLeft: '20px' }}>
                    <li>Lesson title</li>
                    <li>What you will learn</li>
                    <li>Lesson description</li>
                </ul>

                <p>
                    Then choose the <strong>Lesson Language</strong> for the
                    course. You can drag the lesson video you have recorded to
                    the <strong>Media</strong> column, or you can click on
                    'select' to find the lesson video on your computer.
                </p>

                <p>
                    Then, drag the lesson documents you have recorded to the{' '}
                    <strong>Lesson Document</strong> column, or you can click on
                    'select' to find the lesson documents video on your
                    computer.
                </p>

                <p>
                    Click on <strong>'Save'</strong> to successfully upload the
                    lessons and repeat the process for all the lessons you have
                    created.
                </p>
            </div>
        </>
    )
}
