import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../../Home/styles.scss';

ChartJS.defaults.datasets.bar.showBorder = false
ChartJS.defaults.datasets.bar.borderColor = 'rgba(75,192,192,1)'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
);

const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: true, // Allow the chart to scale freely with the container
    scales: {
        x: {
            display: false, // Hides x-axis and its labels
        },
        y: {
            display: false, // Hides y-axis and its labels
        },
    },
    plugins: {
        legend: {
            display: false, // Hides legend if not needed
        },
    },
}

const data = {
    labels: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
    datasets: [
        {
            id: 1,
            label: '',
            tension: 0.6,
            borderWidth: 2,
            borderColor: '#A6CEE3',
            pointRadius: 1,
            pointHoverRadius: 2,
            data: [
                5.5, 5.7, 5.8, 6.5, 5.5, 5.0, 4.8, 5.0, 5.5, 6.4, 5.8, 5.6, 5.8,
                6.3, 6.3, 6.1, 5.8, 5.2, 5.3, 5.5, 5.8, 6.3, 5.9, 5.7, 5.4, 5.7,
                5.8, 6.6,
            ],
        },
    ],
}

export const RevenueInfo = ({ info, filter }) => {
    return (
        <section id="revenue-card" data-testid="revenue-card">
            <div className="head">
                <p data-testid="card-title">{info.title}</p>
                <span className="filter-label" data-testid="filter-label">
                    {filter}
                </span>
            </div>
            <div className="priceHolder" data-testid="price-holder">
                <p>
                    {info.showCurrency && (
                        <span className="priceSymbol" data-testid="priceSymbol">
                            &#36;
                        </span>
                    )}
                    <span>{info.value}</span>
                </p>
            </div>
            <div className="chart">
                <Line options={options} data={data} />
            </div>
        </section>
    )
}