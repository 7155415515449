// import { useState } from 'react'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { useAuth } from './useAuth'

export function useFileUpload() {

    const {
        state: { data }
    } = useAuth()

    // const [isError, setIsError] = useState(false)

    const uploadResourseToS3 = async (fileName, file) => {
        // Replace with your S3 bucket details
        const bucketName = 'courseresource'
        const region = 'us-east-2' // e.g., 'us-east-1'
        const accessKeyId = process.env.REACT_APP_AWS_S3_AK
        const secretAccessKey = process.env.REACT_APP_AWS_S3_SK

        const objectKey = `assets/${data.id}/${fileName}` // You can customize the object key as needed

        const s3Client = new S3Client({
            region,
            credentials: {
                accessKeyId,
                secretAccessKey,
            },
        })
        try {
            const command = new PutObjectCommand({
                Bucket: bucketName,
                Key: objectKey,
                Body: file,
            })

            const signedUrl = await getSignedUrl(s3Client, command, {
                expiresIn: 3600,
            })

            const response = await fetch(signedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                },
            })

            if (response.ok) {
                return { error: null, url: response.url.split('?')[0] }
            } else {
                throw new Error('File upload failed')
                // console.error('File upload failed')
            }
        } catch (error) {
            // setIsError(true)
            console.error('Error uploading file:', error)
            return { error: true, url: null }
        }
    }

    return uploadResourseToS3
}
